import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addpatientDetails } from "./AppointmentSlice";
import { baseUrl, networkCall } from "./Network";
import { getAvailableTimeSlots } from "./Utility";

export const fetchDoctors = createAsyncThunk("api/fetchDoctors", async () => {
  const response = await networkCall("GET", `get_all_doctor_details_by_clinicId?id=${localStorage.getItem("clinic_id")}`);
  if (response.status === 0) {
    throw response.message;
  }
  return response.response;
});

export const bookAppointment = createAsyncThunk(
  "api/bookAppointment",
  async (payload, { dispatch }) => {
    console.log(payload);
    let formData = new FormData();
    formData.append("clinicId", "" + localStorage.getItem("clinic_id"));
    formData.append("doctorsId", payload?.doctorId);
    formData.append("patientName", `${payload?.name}`);
    formData.append("patientAge", `${payload?.age}`);
    formData.append("patientContactNumber", `${payload?.mobile}`);
    formData.append("date", `${payload?.date}`);
    formData.append("time", `${payload?.time}`);
    formData.append("gender", `${payload?.gender}`);
    formData.append("email", `${payload?.email}`);
    formData.append("cp_id", `${payload?.cp_id}`);

    const response = await fetch(baseUrl + "book_appointments", {
      method: "POST",
      body: formData,
    });
    const data = await response.json();
    console.log(data);
    if (data.response !== true) {
      throw new Error("Something went Wrong");
    } else {
      dispatch(
        addpatientDetails({
          userAge: payload?.age,
          name: payload?.name,
          mobile: payload?.mobile,
        })
      );
    }
  }
);

export const fetchSlots = createAsyncThunk(
  "api/fetchSlots",
  async (payload) => {
    console.log(payload);
    const url = `doctor_weekday_slots?Id=${payload?.id}&dow=${payload?.date}&clinicId=${payload?.clinicId}`;
    const response = await networkCall("GET", url);
    console.log(response);
    if (response.status === 0) {
      throw response.message;
    }
    return response.response;
  }
);

const ApiSlice = createSlice({
  name: "api",
  initialState: {
    loading: false,
    error: null,
    doctors: [],
    slotList: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDoctors.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDoctors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        alert(action.error.message);
      })
      .addCase(fetchDoctors.fulfilled, (state, action) => {
        state.loading = false;
        state.doctors = action.payload;
      });

    builder
      .addCase(fetchSlots.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSlots.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        alert(action.error.message);
      })
      .addCase(fetchSlots.fulfilled, (state, action) => {
        state.loading = false;
        const data = action.payload[0];
        console.log("data is ", data);
        console.log("start", data?.start_time);
        console.log("end", data?.end_time);
        console.log("length", data?.consultation_length);
        const slots = getAvailableTimeSlots(
          data?.start_time,
          data?.end_time,
          data?.consultation_length
        );
        if (action.payload.length > 1) {
          const data2 = action.payload[1];
          const slots2 = getAvailableTimeSlots(
            data2?.start_time,
            data2?.end_time,
            data2?.consultation_length
          );

          const slots3 = slots.concat(slots2);
          console.log("slots3:", slots3);
          state.slotList = slots3;
        }
        else {
          state.slotList = slots;
        }


        console.log("state.slotList:", state.slotList);
      });

    builder
      .addCase(bookAppointment.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(bookAppointment.rejected, (state, action) => {
        state.error = action.error.message;
        alert(action.error);
        console.log(action.error);
      })
      .addCase(bookAppointment.fulfilled, (state, action) => {
        state.loading = false;
      });
  },
});

export default ApiSlice.reducer;