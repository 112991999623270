import React from 'react'
import Header from './Header'
import { Link, useNavigate } from "react-router-dom";
import { Button, Divider, Flex, Radio } from 'antd';
import '../App.css';

const IsAuthorized = (props) => {
    const navigate = useNavigate();
    return (
        <div>

            <div className="page-user" style={{ marginTop: 200 }}>
                {/* <div className="topbar-wrap">
                    <Header />
                </div> */}
                <div className="page-content">
                    <div className="container">
                        <div className="row">
                            <div div className="col-xl-4 col-lg-4">

                            </div>
                            < div className="col-xl-4 col-lg-4">
                                <div className="clearfix">
                                    <h4 className="pt-3">Oops! You{"'"}re lost.</h4>
                                    <p className="text-medium-emphasis float-start" style={{ fontSize: 21 }}>
                                        404 Page Not found.
                                    </p>
                                </div>
                                {/* <div style={{ textAlign: 'center', }}>
                                    <Button size={'large'}
                                        onClick={() => { navigate("/Home") }}>
                                        Go Home
                                    </Button>
                                </div> */}

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default IsAuthorized

