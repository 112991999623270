import React, { useState, useEffect } from 'react';
const  {createStore}=require('redux');


export default () => {
    //Const define
    const INCREMENT = "INCREMENT";
    const DECREMENT = "DECREMENT";

    //state define
    const initialCounterState = {
        count: 0
    }
    //action type object
    const incrementCounter = () => {
        return {
            type: INCREMENT
        };
    }
    const decrementCounter = () => {
        return {
            type: DECREMENT
        };
    }

    //Reducers
    const counterReducer = (state = initialCounterState, action) => {
        switch (action.type) {
            case INCREMENT:
                return {
                    ...state,
                    count: state.count + 1,
                }
            case DECREMENT:
                return {
                    ...state,
                    count: state.count - 1,
                }
            default:
                return{
                    state
                }

        }

    }

    //create store
    const store=createStore(counterReducer);
    store.subscribe(()=>{
        console.log("value= ",store.getState())
    })

    //dispatch action
    store.dispatch(incrementCounter());


    return (
        <>
            <p>Practice Redux</p>
        </>
    )
}