import React, { useEffect, useState, useRef, useCallback } from 'react';
import '../App.css';
import '../css/Global.css';
import Axios from 'axios';
import camera from '../mainassets/camera.png';
import documents from '../mainassets/documents.png';
import Webcam from 'react-webcam';
import back from '../mainassets/back.png'
import $ from "jquery";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import select from '../mainassets/select.jpg';
import cross from '../mainassets/cross.png';
import cameraSwitch from '../mainassets/cameraSwitch.png';
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useNavigate, useLocation } from "react-router-dom";
import deleteicon from '../mainassets/delete-icon.jpg';
import BackToDashboard from '../components/BackToDashboard';
import upload from '../mainassets/upload.png';
import Header from '../components/Header'
import { BiFolderPlus } from "react-icons/bi";
import { FcImageFile } from "react-icons/fc";
import { AiFillFilePdf } from "react-icons/ai";
import pdf from '../mainassets/pdf.png';
import imageCompression from 'browser-image-compression';

export default () => {

    let compressedFile;
    let url;
    const location = useLocation();

    const [isShowCamera, setIsShowCamera] = useState(false);
    const [capturedImage, setCapturedImage] = useState("");
    const [document_name, setDocument_name] = useState("");
    const [fileOpen, setFileOpen] = useState(false);
    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [consultationId, setConsultationId] = useState("");
    const [patientid, setPatientid] = useState("");
    const [uploadedDocuments, setUploadedDocuments] = useState([]);
    const [isShowDocNameContainer, setIsShowDocNameContainer] = useState(false);
    const [file_type, setFile_type] = useState("");
    const [buttonHide, setButtonHide] = useState(false);
    const [cameraOpen, setCameraOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [selectFile, setSelectFile] = useState(false);
    const [uploadedDocId, setUploadedDocId] = useState("");
    const [isDeleteDoc, setIsDeleteDoc] = useState(false);
    const [stepThree, setStepThree] = useState(false);
    const [myFile, setMyFile] = useState(null);
    const [base64ImageToFile, setBase64ImageToFile] = useState("");
    const [isEmptyDocumentName, setIsEmptyDocumentName] = useState(false);
    const [reportsCategory, setReportsCategory] = useState("");
    const [isEmptyDoc, setIsEmptyDoc] = useState(false);
    const [consultation_Id, setConsultation_Id] = useState(Number);

    const [isUploadHomeScreen, setIsUploadHomeScreen] = useState(true);
    const [isCameraScreen, setIsCameraScreen] = useState(false);
    const [isCapturerdPhotoScreen, setIsCapturerdPhotoScreen] = useState(false);
    const [isSelectedPhoto, setIsSelectedPhoto] = useState(false);
    const [imageURL, setImageURL] = useState(null);
    const [reportList, setReportList] = useState([]);
    const navigate = useNavigate();
    let userRole = '';


    const fileInputRef = useRef()

    let v_selectedfile;

    const FACING_MODE_USER = "user";
    const FACING_MODE_ENVIRONMENT = "environment";

    const videoConstraints = {
        facingMode: FACING_MODE_ENVIRONMENT
    };

    const [facingMode, setFacingMode] = useState(FACING_MODE_ENVIRONMENT);

    const handleClick = useCallback(() => {
        setFacingMode(
            prevState =>
                prevState === FACING_MODE_ENVIRONMENT
                    ? FACING_MODE_USER
                    : FACING_MODE_ENVIRONMENT
        );
    }, []);
    let id = '';

    useEffect(() => {

        setDeleteOpen(false);
        console.log(location.state)

        // if (location.state != "") {
        setConsultation_Id(location.state.consultation_id);
        setFirstName(location.state.first_name);
        setLastName(location.state.last_name);
        setConsultationId(location.state.id);
        setPatientid(location.state.patients_id_fk);

        //uploaded documents
        Axios.get(`${global.baseUrl}/api/clinicapi/PatientCheckout/get_uploaded_documents?consultation_id_fk=${location.state.id}`).then((response) => {
            console.log("savehandler: uploaded documents success response ", response.data);
            if (response.data.status == '1') {
                if (response.data.response.length > 0) {
                    setUploadedDocuments(response.data.response);
                } else {
                    setIsEmptyDoc(true);
                }
            }

        });
        Axios.get(`${global.baseUrl}/api/commonapi/PickList/get_report_name`).then((response) => {
            console.log(response.data);
            if (response.data.status == '1') {
                setReportList(response.data.response);
            }
        });

    }, [])



    const fileChangeHandler = async (event) => {

        setSelectFile(true);

        console.log("fileChangeHandler: selectedfile  ", event.target.files[0])
        setFile_type(event.target.files[0].type);


        const options1 = {
            maxSizeMB: 0.2,
            maxWidthOrHeight: 1000,
            useWebWorker: true,
        };




        try {
            if (event.target.files[0].size > 204800) {
                compressedFile = await imageCompression(event.target.files[0], options1);
                console.log("compressed file ", compressedFile)
                const file = new File([compressedFile], `${compressedFile.name}.jpeg`, { type: "image/jpeg" })
                setMyFile(file);
                url = URL.createObjectURL(compressedFile)
            }
            else {
                setMyFile(event.target.files[0]);
                url = URL.createObjectURL(event.target.files[0])
            }



            console.log("url", url)
            setImageURL(url)

        }
        catch (error) {
            console.log(error);
        }
    }

    const uploadFile = (e) => {
        console.log("work")
        console.log("myFile", myFile)
        if (document_name != "" && reportsCategory != "") {
            // const url = "http://localhost/react-file-upload/index.php";
            setIsEmptyDocumentName(false);
            setButtonHide(true);

            e.preventDefault();
            const datafile = new FormData();
            // let formdata = $(e.target);
            datafile.append("file", myFile);
            datafile.append('constid', consultationId);
            datafile.append('clinicid', localStorage.getItem("clinic_id"));
            $.ajax({
                type: "POST",
                url: `${global.baseUrl}/api/commonapi/FileUpload/file_insert_emr`,
                data: datafile,
                processData: false,
                contentType: false,
                success(data) {
                    console.log("savehandler:file upload success response:  ", data);
                    setButtonHide(false);

                    if (data) {
                        const filedata = new FormData();
                        filedata.append("file", data);
                        filedata.append('patients_id_fk', patientid);
                        filedata.append('consultation_id_fk', consultationId);
                        filedata.append('category', reportsCategory);
                        filedata.append('document_name', document_name);
                        filedata.append('document_type', file_type);

                        $.ajax({
                            type: "POST",
                            url: `${global.baseUrl}/api/clinicapi/PatientCheckout/photoupload`,
                            data: filedata,
                            processData: false,
                            contentType: false,
                            success(data) {
                                console.log("savehandler:file upload success response:  ", data);
                                setButtonHide(false);
                                setSelectFile(false);
                                window.location.reload(false);


                            },
                            error: function (err) {
                                console.log(err.responseText);
                                alert("error has occured  ", err);
                            }
                        });

                    }


                },
                error: function (err) {
                    console.log(err.responseText);
                    alert("error has occured  ", err);
                }
            });
        }
        else {
            e.preventDefault();
            console.log("documnt is empty")
            setIsEmptyDocumentName(true);

        }

    }

    const documentNameChangeHandler = (e) => {
        setDocument_name(e.target.value);

    }
    const reportsCategoryChangeHandler = (e) => {
        setReportsCategory(e.target.value);
        if (e.target.value == "Prescription") {
            setDocument_name("Prescription");
        }

    }

    const startCam = () => {
        console.log("camera is opening");
        setIsUploadHomeScreen(false);
        setIsCapturerdPhotoScreen(false);
        setIsCameraScreen(true);
        setStepThree(false);
        setCameraOpen(true);
        setFileOpen(false);
        setIsShowCamera(true);
        setCapturedImage("");


    }
    const selectedPhotoHandler = () => {

        setIsUploadHomeScreen(true);
        setCameraOpen(false);
        setIsSelectedPhoto(true);

    }



    const webcamRef = React.useRef(null);
    // const capturePhoto = () => {
    const capturePhoto = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();
            setCapturedImage(imageSrc);
            setIsCapturerdPhotoScreen(true);

            setStepThree(true);
            setIsShowDocNameContainer(true);
            setSelectFile(false);
            setFileOpen(false);

            console.log("captured photo  ", imageSrc);
            const url = imageSrc;
            fetch(url)
                .then(res => res.blob())
                .then(blob => {
                    const file = new File([blob], "Filename.jpeg", { type: "image/jpeg" })
                    console.log(file);
                    setBase64ImageToFile(file);
                    setFile_type("image/jpeg");
                    // setCapturedImage(file)
                })


        },
        [webcamRef]
    );

    // }

    const stopCam = () => {
        setIsCameraScreen(false);
        setIsUploadHomeScreen(true);
        setCameraOpen(false);
        setFileOpen(false);
        setIsShowCamera(false);
        setSelectFile(false);
        setIsShowDocNameContainer(false)

    }


    const saveHandler = (e) => {

        if (document_name != "" && reportsCategory != "") {
            setIsEmptyDocumentName(false);
            setCapturedImage("");
            setButtonHide(true);
            setCameraOpen(false);
            e.preventDefault();
            let formdata = $(e.target);
            formdata = new FormData();
            formdata.append('constid', consultationId);
            formdata.append('clinicid', localStorage.getItem("clinic_id"));
            formdata.append('file', base64ImageToFile);
            $.ajax({
                type: "POST",
                url: `${global.baseUrl}/api/commonapi/FileUpload/file_insert_emr`,
                data: formdata,
                processData: false,
                contentType: false,
                success(data) {
                    console.log("savehandler:file upload success response:  ", data);

                    if (data) {
                        const filedata = new FormData();
                        filedata.append("file", data);
                        filedata.append('patients_id_fk', patientid);
                        filedata.append('consultation_id_fk', consultationId);
                        filedata.append('category', reportsCategory);
                        filedata.append('document_name', document_name);
                        filedata.append('document_type', file_type);

                        $.ajax({
                            type: "POST",
                            url: `${global.baseUrl}/api/clinicapi/PatientCheckout/photoupload`,
                            data: filedata,
                            processData: false,
                            contentType: false,
                            success(data) {
                                console.log("savehandler:file upload success response:  ", data);
                                setButtonHide(false);


                                window.location.reload(false);


                            },
                            error: function (err) {
                                console.log(err.responseText);
                                alert("error has occured  ", err);
                            }
                        });

                    }
                },
                error: function (err) {
                    console.log(err.responseText);
                    alert("error has occured  ", err);
                    setButtonHide(false);
                }
            });
        } else {
            e.preventDefault();
            console.log("documnt is empty")
            setIsEmptyDocumentName(true);
        }
    }




    const handleDeleteClickOpen = (id) => {
        setDeleteOpen(true);
        setUploadedDocId(id);
    };

    const handleDeleteClose = () => {
        setDeleteOpen(false);
    };

    const openNewTabHandler = (id, file, fileType) => {
        console.log(" openNewTabHandler File Type= ", fileType);
        const url = `FileReader?constid=${id}&clinicid=${localStorage.getItem("clinic_id")}&file=${file}&fileType=${fileType}`;
        window.open(url, "toolbar=yes,scrollbars=yes,resizable=yes,width=400,height=400");

    }


    const deleteDocumentsHandler = (e) => {
        // console.log(uploadedDocId);
        setIsDeleteDoc(true);
        e.preventDefault();
        let formdata = $(e.target);
        formdata = new FormData();
        formdata.append('id', uploadedDocId);

        $.ajax({
            type: "POST",
            url: `${global.baseUrl}/api/clinicapi/PatientCheckout/delete_documents?id=${uploadedDocId}`,
            data: formdata,
            processData: false,
            contentType: false,
            success(data) {
                console.log("deleted document response", data);
                // alert("Document deleted");
                setIsDeleteDoc(false);
                setDeleteOpen(false);

                Axios.get(`${global.baseUrl}/api/clinicapi/PatientCheckout/get_uploaded_documents?consultation_id_fk=${consultationId}`).then((response) => {
                    console.log("savehandler: uploaded documents success response ", response.data);
                    if (response.data.status == '1') {
                        setUploadedDocuments(response.data.response);
                        setSelectFile(false);
                    }
                });


            },
            error: function (err) {
                console.log(err.responseText);
                setIsDeleteDoc(false);
                alert("Error has occured  ", err.responseText);
            }
        });


    }


    const triggerUpload = () => {
        fileInputRef.current.click()
    }


    return (

        <div className="page-user">
            <div className="topbar-wrap">
                <Header />
            </div>
            <div>
                <div className="page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4">
                                <div style={{ textAlign: 'left', marginTop: -10 }}>
                                    <BackToDashboard />
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                            </div>
                            <div className="col-xl-4 col-lg-4">

                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div style={{ marginTop: 10 }} className="page-content">
                <div className="container">

                    {isUploadHomeScreen == true ?

                        <div className="camera_open_section">

                            <div className="row">

                                <div style={{ borderRadius: 10 }} className="col-xl-6 col-lg-6">
                                    <div className="content-area card">


                                        <div className="flex-div" style={{ justifyContent: 'flex-start', marginLeft: 10 }}>
                                            <p className="value" >Consultation id:&nbsp;{consultation_Id} </p>
                                            <p className="label" style={{ marginLeft: 10 }} > {firstname} {lastname}</p>
                                        </div>
                                        <p style={{ textAlign: 'left', marginLeft: 10 }} className="card-title">Uploaded Documents</p>



                                        {
                                            uploadedDocuments.map((details) => {
                                                return (
                                                    <div key={details.file} className="flex-div" style={{ paddingTop: 10, marginLeft: 10 }} >
                                                        <div   >
                                                            <div className="flex-div">
                                                                {
                                                                    details.document_type == "application/pdf" ?
                                                                        // <img onClick={() => { openNewTabHandler(details.consultation_id_fk, details.file, details.document_type) }} src={pdf} className="doc-image" />
                                                                        <AiFillFilePdf className="doc-image" size={32} onClick={() => { openNewTabHandler(details.consultation_id_fk, details.file, details.document_type) }} />
                                                                        :
                                                                        // <img onClick={() => { openNewTabHandler(details.consultation_id_fk, details.file, details.document_type) }} src={documents} className="doc-image" />
                                                                        <FcImageFile className="doc-image" size={32} onClick={() => { openNewTabHandler(details.consultation_id_fk, details.file, details.document_type) }} />
                                                                }

                                                                <img src={deleteicon} style={{ height: 30, width: 30, marginLeft: 20, }} onClick={() => { handleDeleteClickOpen(details.id) }} />
                                                                <p className="value" style={{ marginLeft: 15 }}>{details.create_date_time}</p>
                                                                <p className="value" style={{ marginLeft: 5 }}>

                                                                    {reportList.map((val) => {
                                                                        return (
                                                                            <>
                                                                                {
                                                                                    val.code == details.document_name ?
                                                                                        <p >{val.value}</p> : null
                                                                                }
                                                                            </>

                                                                        )
                                                                    })}
                                                                </p>

                                                            </div>


                                                        </div>

                                                        <Dialog
                                                            open={deleteOpen}
                                                            onClose={handleDeleteClose}
                                                            aria-labelledby="alert-dialog-title"
                                                            aria-describedby="alert-dialog-description"
                                                        >
                                                            <DialogTitle id="alert-dialog-title">
                                                                {"Are you sure you want to delete this document?"}
                                                            </DialogTitle>
                                                            <DialogContent>
                                                                <DialogContentText id="alert-dialog-description">

                                                                </DialogContentText>
                                                            </DialogContent>
                                                            <DialogActions>
                                                                <Button onClick={handleDeleteClose}>Cancel</Button>
                                                                {
                                                                    isDeleteDoc == false ?
                                                                        <form
                                                                            method="post"
                                                                            onSubmit={(event) => deleteDocumentsHandler(event)}

                                                                        >
                                                                            <Button type="submit" autoFocus>
                                                                                Delete
                                                                            </Button>
                                                                        </form>
                                                                        :
                                                                        <p style={{ color: 'red', fontSize: 15, fontWeight: 'bold', marginLeft: 10, marginTop: -5 }}>Deleting...</p>
                                                                }

                                                            </DialogActions>
                                                        </Dialog>

                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                    {
                                        isEmptyDoc ?
                                            <p style={{ marginTop: 20, fontSize: 14, fontWeight: 'bold', color: 'red', textAlign: 'left', marginLeft: 10 }}>No Document</p>
                                            :
                                            null
                                    }
                                </div>
                                <div style={{ backgroundColor: '#F8F3F3', borderRadius: 10, paddingTop: 30, paddingRight: 30 }} className="col-xl-6 col-lg-6">
                                    <div style={{ paddingBottom: 30, paddingRight: 20 }} className="content-area card">
                                        <div style={{ textAlign: 'left', marginLeft: 10, paddingRight: 20 }}>
                                            <p style={{ color: 'red', fontSize: 14, fontWeight: 'bold' }}>Only JPEG & PNG & PDF files are allowed.</p>
                                            <p style={{ marginTop: 10, fontSize: 12, fontWeight: 'bold' }}>Select a document category to upload *</p>
                                            <div className="select-wrapper">
                                                <select className="input-bordered"
                                                    style={{ fontWeight: 'bold' }}
                                                    name="reportsCategory"
                                                    value={reportsCategory}
                                                    onChange={(event) => reportsCategoryChangeHandler(event)}

                                                >
                                                    <option value=" "> Select</option>
                                                    <option value="Lab Reports"> Lab reports</option>
                                                    <option value="Prescription">Prescription</option>

                                                </select>

                                            </div>
                                            <p style={{ marginTop: 10, fontSize: 12, fontWeight: 'bold' }}>Select a report name to upload *</p>


                                            <div className="select-wrapper">

                                                <select className="input-bordered"
                                                    style={{ fontWeight: 'bold' }}
                                                    name="document_name"
                                                    value={document_name}
                                                    onChange={(event) => documentNameChangeHandler(event)}

                                                >

                                                    <option value="">Select</option>
                                                    {reportList.map((val) => {
                                                        return (
                                                            <>
                                                                <option value={val.code} >{val.value} </option>
                                                            </>
                                                        );
                                                    })}
                                                </select>
                                            </div>

                                            {isEmptyDocumentName ?
                                                <p style={{ color: 'red', fontSize: 15, fontWeight: 'bold', marginTop: 5 }}> Please fill all the fields marked with * </p>
                                                : null
                                            }



                                            {(capturedImage != "" && fileOpen == false) || (selectFile == true) ?
                                                <>
                                                    {isShowDocNameContainer == true ?

                                                        <div style={{ width: '100%', marginTop: 30 }}>
                                                            <img name="file" src={capturedImage} style={{ height: 350, width: 'auto' }} />
                                                        </div>
                                                        : null
                                                    }
                                                </>
                                                :
                                                null

                                            }

                                            {
                                                isSelectedPhoto == false ?
                                                    <>
                                                        {file_type != "application/pdf" ?
                                                            imageURL && <img src={imageURL} alt="Upload Preview" style={{ height: 100, width: 100, textAlign: 'left', marginTop: 30 }} />
                                                            :
                                                            <>
                                                                {console.log(file_type)}
                                                                <img src={pdf} alt="Upload Preview" style={{ height: 50, width: 50, textAlign: 'left', marginTop: 30 }} />

                                                            </>

                                                        }


                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                            <input className="fileinputstyle" style={{ marginTop: 15 }} type="file" name="mfile" id="mfile" ref={fileInputRef} onChange={(event) => fileChangeHandler(event)} />

                                                            <button style={{ marginTop: 20, height: 45, marginLeft: 68 }} onClick={triggerUpload} class="btn-primary btn-circle"><BiFolderPlus size={30} /></button>
                                                            <p style={{ fontSize: 11, fontWeight: '600', marginTop: 65, marginLeft: -90, width: 144 }}>Select from your device *</p>
                                                            <div className="documents" style={{ marginTop: 10 }} onClick={startCam} >
                                                                <img src={camera} style={{ height: 20, width: 20, marginTop: 20, marginLeft: 15 }} />
                                                                <p style={{ fontSize: 11, fontWeight: '600', marginTop: 15, marginLeft: -5, width: 100 }}>Capture Photo</p>
                                                            </div>

                                                        </div>


                                                    </>
                                                    :
                                                    null
                                            }

                                        </div>

                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 5, width: '100%', justifyContent: 'space-evenly', height: 'auto', backgroundColor: 'rgb(248, 243, 243)', paddingBottom: 10, paddingTop: 10 }}>

                                        <div className="documents" onClick={() => { navigate("/CheckoutList") }}   >
                                            <img src={back} style={{ height: 20, width: 20, marginTop: 15 }} />
                                            <p style={{ fontSize: 12, fontWeight: '600', marginTop: 15, width: 180, textAlign: 'left', marginLeft: 15 }}>Exit</p>

                                        </div>
                                        {isSelectedPhoto == false ?
                                            <>
                                                {
                                                    buttonHide == false ?
                                                        <form method="post"
                                                            onSubmit={(event) => uploadFile(event)}
                                                        >

                                                            <button className="documents"   >
                                                                <img src={upload} style={{ height: 20, width: 20 }} />
                                                            </button>
                                                        </form>
                                                        :
                                                        <p style={{ marginTop: 10, color: 'green', fontSize: 12, fontWeight: 'bold' }}>Uploading document...</p>
                                                }
                                            </>
                                            :
                                            null
                                        }


                                        {isSelectedPhoto == true ?
                                            <>
                                                {
                                                    buttonHide == false ?
                                                        <form method="post"
                                                            onSubmit={(event) => saveHandler(event)}
                                                        >

                                                            <button className="documents"   >
                                                                <img src={upload} style={{ height: 20, width: 20, marginTop: 15 }} />

                                                            </button>

                                                        </form>
                                                        :
                                                        <p style={{ marginTop: 10, color: 'green', fontSize: 12, fontWeight: 'bold' }}>Uploading document...</p>
                                                }
                                            </>
                                            :
                                            null
                                        }

                                    </div>
                                </div>
                            </div>
                            {
                                fileOpen == true ?
                                    <div style={{ marginTop: 180, textAlign: 'center', marginLeft: 10 }}>
                                        <p style={{ marginTop: 10, color: 'red', fontSize: 14, fontWeight: 'bold' }}>Only JPEG & PNG & PDF files are allowed.</p>
                                    </div>
                                    :
                                    null
                            }

                        </div>
                        :
                        null

                    }

                    {isShowCamera && cameraOpen == true && capturedImage == "" ?
                        <>
                            <Webcam

                                style={{ height: 350, width: 'auto' }}

                                ref={webcamRef}

                                audio={false}
                                screenshotFormat="image/jpeg"
                                videoConstraints={{
                                    ...videoConstraints,
                                    facingMode
                                }}

                            />

                        </>
                        : null
                    }

                    {(capturedImage != "" && fileOpen == false && isSelectedPhoto == false) || (selectFile == true) ?
                        <>
                            {isShowDocNameContainer == true ?

                                <div style={{ width: '100%' }}>
                                    <img name="file" src={capturedImage} style={{ height: 350, width: 'auto' }} />
                                </div>
                                : null
                            }
                        </>
                        :
                        null

                    }

                    {
                        isCameraScreen ?

                            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-evenly', height: 'auto', paddingBottom: 10, paddingTop: 10, marginTop: 20 }}>
                                {

                                    (fileOpen == false && stepThree == false) || (fileOpen == true && selectFile == false) ?
                                        <>

                                            {
                                                isShowCamera == true || (fileOpen == true && selectFile == false) ?
                                                    <button className="common_button" onClick={stopCam}> <img src={back} style={{ height: 15, width: 15 }} />  </button>
                                                    : null
                                            }
                                            {
                                                fileOpen == false && stepThree == false ?
                                                    <button className="common_button" onClick={capturePhoto}> <img src={camera} style={{ height: 18, width: 18 }} />&nbsp;</button>
                                                    : null
                                            }
                                        </>
                                        : null
                                }

                                {
                                    fileOpen == false && stepThree == false ?

                                        <button className="common_button" onClick={handleClick} > <img src={cameraSwitch} style={{ height: 18, width: 18, paddingRight: 3 }} /></button>
                                        : null
                                }


                                {
                                    isCapturerdPhotoScreen == true && fileOpen == false && isSelectedPhoto == false ?
                                        <>
                                            <button className="common_button" onClick={startCam} > <img src={camera} style={{ height: 15, width: 15 }} /> </button>
                                            <button className="common_button" onClick={selectedPhotoHandler} > <img src={select} style={{ height: 21, width: 21 }} />  </button>
                                            <button className="common_button" onClick={stopCam} > <img src={cross} style={{ height: 12, width: 12 }} />  </button>

                                        </>
                                        : null

                                }

                            </div>
                            :
                            null
                    }
                </div>
            </div>
        </div>
    )

}