import { atom } from 'recoil';

export const consultation_vitals_info = atom({
    key: 'consultation_vitals_info',
    default: [],
});
export const life_style_info = atom({
    key: 'life_style_info',

    // default: { id:'', patient_id: '', tobaco:'', alcohol: '', physical_activity: '' },
    default: [],
});
export const family_history1_info = atom({
    key: 'family_history1_info',
    default: [],
});
export const family_history2_info = atom({
    key: 'family_history2_info',
    default: [],
});
export const user_role = atom({
    key: 'user_role',
    default: [],
});

