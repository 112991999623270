import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDoctors } from "../../Store/ApiSlice";
import { selectDoctor } from "../../Store/AppointmentSlice";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import moment from "moment/moment";
import Axios from "axios";


function DoctorList() {
  const [docTimings, setDocTimings] = useState([]);
  const [docDetails, setDocDetails] = useState([]);
  const [isNoTimings, setIsNoTimings] = useState(false);


  const dispatch = useDispatch();
  const doctorList = useSelector((state) => {
    return state.api.doctors;
  });

  useEffect(() => {



    dispatch(fetchDoctors());



    Axios.get(
      `${global.baseUrl}/api/clinicapi/Doctors/get_doctors_timings_by_clinic_id?id=${localStorage.getItem("clinic_id")}`
    ).then((response) => {
      console.log(response.data);
      if (response.data.status == '1') {
        setDocTimings(response.data.response);
      }

    });





  }, [dispatch]);

  return (
    <div
      className="content  mobile-row"
      style={{ width: "100%", marginLeft: "20px" }}
    >
      <div className="main-box" >
        {/* <p
          style={{
            textAlign: "start",
            fontSize: "18px",
            fontWeight: "bold",
            color: "blue",
            margin: "12px",
          }}
        >
          Doctor's
        </p> */}



        <div style={{ textAlign: 'left' }} className="page-content">
          <div className="container">
            <div className="row">

              {doctorList?.map((val, key) => {
                return (

                  <div className="col-xl-4 col-lg-4">
                    <div className="token-transaction card card-full-height">
                      <div className="card-innr">
                        <div style={{ height: 300 }} className="card-content">
                          <div
                            // className="page-slide-up"
                            key={key}
                            onClick={() => {
                              const data = {
                                name: val?.first_name + " " + val?.last_name,
                                id: val?.id,
                              };
                              dispatch(selectDoctor(data));
                            }}
                            style={{
                              display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                              boxShadow: "rgba(17, 17, 26, 0.2) 0px 0px 16px",
                              // padding: "0px",
                              // margin: "4px",
                              // marginTop: "12px",
                              width: 300,
                              borderRadius: "6px",
                              animationDelay: `${key * 100}ms`,
                            }}
                          >
                            <div
                            // className="mobile-row"
                            // style={{
                            //   cursor: "pointer",
                            //   borderRadius: "4px",
                            //   alignItems: "center",
                            //   width: "100%",
                            // }}
                            >
                              {/* <img
                  className="doctor-img"
                  alt=""
                  src="https://t3.ftcdn.net/jpg/02/60/04/08/360_F_260040863_fYxB1SnrzgJ9AOkcT0hoe7IEFtsPiHAD.jpg"
                /> */}
                              <div
                                style={{
                                  padding: "8px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginTop: "8px",
                                  flexDirection: "row",
                                  alignItems: "flex-start",
                                  width: "100%",

                                }}
                              >
                                <div style={{ textAlign: "start" }}>
                                  <h6 style={{ color: "blue", fontSize: "16px", margin: 0, fontWeight: 'bold' }}>
                                    {val.first_name} {val.last_name}
                                  </h6>
                                  {/* <p style={{ margin: 0 }}>{val.specialization}</p> */}
                                  <div style={{ textAlign: "start" }}>
                                    {/* <textarea
                                      name="specialization"
                                      value={val.specialization}
                                      style={{ fontSize: 14, fontWeight: 'normal', color: 'black', height: 40, width: '100%', border: 'none', overflow: 'hidden' }}

                                    ></textarea> */}
                                    <p style={{ fontSize: 14, fontWeight: 'normal', color: 'black', width: '100%', border: 'none', overflow: 'hidden' }}
                                    > {val.specialization}</p>

                                  </div>
                                  <p style={{ fontSize: 14, fontWeight: 'bold', textAlign: 'left', color: 'skyblue' }}>Timings:</p>
                                  <div style={{
                                    // overflowY: 'scroll',
                                    // height: 150,

                                  }}>
                                    {docTimings.map((value) => {
                                      return (
                                        <div key={value.id}>
                                          <div
                                            style={{
                                              width: "100%",
                                              display: "flex",
                                              flexDirection: "row",
                                              textAlign: "left",



                                            }}
                                          >
                                            {val.id == value.doctors_id_fk ? (
                                              <>
                                                {value.day_of_week == "0" ? (
                                                  <>
                                                    <AccessTimeIcon />

                                                    {value.is_open == "1" ? (
                                                      <>
                                                        <p
                                                          className="value"
                                                          style={{ marginLeft: 5 }}
                                                        >
                                                          Sun : Open{" "}
                                                          {moment(
                                                            value.start_time,
                                                            "hh:mm"
                                                          ).format("LT")}{" "}
                                                          -{" "}
                                                          {moment(
                                                            value.end_time,
                                                            "hh:mm"
                                                          ).format("LT")}
                                                        </p>
                                                      </>
                                                    ) : (
                                                      <p
                                                        className="value"
                                                        style={{ marginLeft: 5 }}
                                                      >
                                                        Sun : Close
                                                      </p>
                                                    )}
                                                  </>
                                                ) : null}

                                                {value.day_of_week == "1" ? (
                                                  <>
                                                    <AccessTimeIcon />

                                                    {value.is_open == "1" ? (
                                                      <>
                                                        <p
                                                          className="value"
                                                          style={{ marginLeft: 5 }}
                                                        >
                                                          Mon : Open
                                                          {moment(
                                                            value.start_time,
                                                            "hh:mm"
                                                          ).format("LT")}{" "}
                                                          -{" "}
                                                          {moment(
                                                            value.end_time,
                                                            "hh:mm"
                                                          ).format("LT")}
                                                        </p>
                                                      </>
                                                    ) : (
                                                      <p
                                                        className="value"
                                                        style={{ marginLeft: 5 }}
                                                      >
                                                        Mon : Close
                                                      </p>
                                                    )}
                                                  </>
                                                ) : null}

                                                {value.day_of_week == "2" ? (
                                                  <>
                                                    <AccessTimeIcon />

                                                    {value.is_open == "1" ? (
                                                      <>
                                                        <p
                                                          className="value"
                                                          style={{ marginLeft: 5 }}
                                                        >
                                                          Tue : Open{" "}
                                                          {moment(
                                                            value.start_time,
                                                            "hh:mm"
                                                          ).format("LT")}{" "}
                                                          -{" "}
                                                          {moment(
                                                            value.end_time,
                                                            "hh:mm"
                                                          ).format("LT")}
                                                        </p>
                                                      </>
                                                    ) : (
                                                      <p
                                                        className="value"
                                                        style={{ marginLeft: 5 }}
                                                      >
                                                        Tue : Close
                                                      </p>
                                                    )}
                                                  </>
                                                ) : null}

                                                {value.day_of_week == "3" ? (
                                                  <>
                                                    <AccessTimeIcon />

                                                    {value.is_open == "1" ? (
                                                      <>
                                                        <p
                                                          className="value"
                                                          style={{ marginLeft: 5 }}
                                                        >
                                                          Wed : Open{" "}
                                                          {moment(
                                                            value.start_time,
                                                            "hh:mm"
                                                          ).format("LT")}{" "}
                                                          -{" "}
                                                          {moment(
                                                            value.end_time,
                                                            "hh:mm"
                                                          ).format("LT")}
                                                        </p>
                                                      </>
                                                    ) : (
                                                      <p
                                                        className="value"
                                                        style={{ marginLeft: 5 }}
                                                      >
                                                        Wed : Close
                                                      </p>
                                                    )}
                                                  </>
                                                ) : null}

                                                {value.day_of_week == "4" ? (
                                                  <>
                                                    <AccessTimeIcon />

                                                    {value.is_open == "1" ? (
                                                      <>
                                                        <p
                                                          className="value"
                                                          style={{ marginLeft: 5 }}
                                                        >
                                                          Thurs : Open{" "}
                                                          {moment(
                                                            value.start_time,
                                                            "hh:mm"
                                                          ).format("LT")}{" "}
                                                          -{" "}
                                                          {moment(
                                                            value.end_time,
                                                            "hh:mm"
                                                          ).format("LT")}
                                                        </p>
                                                      </>
                                                    ) : (
                                                      <p
                                                        className="value"
                                                        style={{ marginLeft: 5 }}
                                                      >
                                                        Thurs : Close
                                                      </p>
                                                    )}
                                                  </>
                                                ) : null}

                                                {value.day_of_week == "5" ? (
                                                  <>
                                                    <AccessTimeIcon />

                                                    {value.is_open == "1" ? (
                                                      <>
                                                        <p
                                                          className="value"
                                                          style={{ marginLeft: 5 }}
                                                        >
                                                          Fri : Open{" "}
                                                          {moment(
                                                            value.start_time,
                                                            "hh:mm"
                                                          ).format("LT")}{" "}
                                                          -{" "}
                                                          {moment(
                                                            value.end_time,
                                                            "hh:mm"
                                                          ).format("LT")}
                                                        </p>
                                                      </>
                                                    ) : (
                                                      <p
                                                        className="value"
                                                        style={{ marginLeft: 5 }}
                                                      >
                                                        Fri : Close
                                                      </p>
                                                    )}
                                                  </>
                                                ) : null}
                                                {value.day_of_week == "6" ? (
                                                  <>
                                                    <AccessTimeIcon />

                                                    {value.is_open == "1" ? (
                                                      <>
                                                        <p
                                                          className="value"
                                                          style={{ marginLeft: 5 }}
                                                        >
                                                          Sat : Open{" "}
                                                          {moment(
                                                            value.start_time,
                                                            "hh:mm"
                                                          ).format("LT")}{" "}
                                                          -{" "}
                                                          {moment(
                                                            value.end_time,
                                                            "hh:mm"
                                                          ).format("LT")}
                                                        </p>
                                                      </>
                                                    ) : (
                                                      <p
                                                        className="value"
                                                        style={{ marginLeft: 5 }}
                                                      >
                                                        Sat : Close
                                                      </p>
                                                    )}
                                                  </>
                                                ) : null}
                                              </>
                                            ) : null}
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>





                              </div>


                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="appointment-box" style={{ display: "flex" }}>
        <div style={{ width: 1, height: "100%", backgroundColor: "blue" }} />

        <div style={{ width: "100%", margin: "20px" }}>
          <p style={{ color: "black", textAlign: "start" }}>Your Appoinment</p>

          <div>
            <p
              style={{
                color: "black",
                padding: "8px 20px",
                border: "1px solid blue",
                width: "100%",
                borderRadius: "4px",
              }}
            >
              No Appointment Found
            </p>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default DoctorList;