import './App.css';
import Test from './pages/Test';
import Doctors from './pages/Doctors';
import Login from './pages/Login';
import Home from './pages/Home';
import NewPatientRegister from './pages/NewPatientRegister';
import PatientsCheckin from './pages/PatientsCheckin';
import AddDoctor from './pages/AddDoctor';
import CustomerSupport from './pages/CustomerSupport';
import Consultation from './pages/Consultation';
import MyPatients from './pages/MyPatients';
import ConsultationHistory from './pages/ConsultationHistory';
import UploadPatientsRecords from './pages/UploadPatientsRecords';
import ConsultationHistoryDetails from './pages/ConsultationHistoryDetails';
import EditSchedule from './pages/EditSchedule';
import PrintPrescription from './pages/PrintPrescription';
import EditDoctorProfile from './pages/EditDoctorProfile';
import NewPatientRegisterForDoctor from './pages/NewPatientRegisterForDoctor';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Invoice from './pages/Invoice';
import SelfCheckout from './pages/SelfCheckout';
import PaperConsultation from './pages/PaperConsultation';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Appointment from './pages/Appointment/Appointment';
import ViewAppointment from "./pages/Slots/ViewAppointment";
import AddSlot from "./pages/Slots/AddSlot";

import './css/Global.css';
import PatientServiceInvoiceAdd from './pages/PatientServiceInvoiceAdd';
import PatientInvoiceAdd from './pages/PatientInvoiceAdd';
import CheckoutList from './pages/CheckoutList';
import Payment from './components/Payment';
import ViewInvoice from './pages/ViewInvoice';
import MoneyReceipt from './pages/MoneyReceipt';
import Payments from './pages/Payments';
import PaymentReport from './pages/PaymentReport';
import Feemaster from './pages/Feemaster';
import FeeMasterList from './pages/FeeMasterList';
import FeeMasterAdd from './pages/FeeMasterAdd';
import FeeMasterUpdate from './pages/FeeMasterUpdate';
import CheckinForm from './pages/CheckinForm';
import FileReader from './pages/FileReader';
import DoctorRegister from './pages/DoctorRegister';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Termscondition from './pages/Terms&Conditions';
import ViewAppointments from './pages/ViewAppointments';
import PatientFeedback from './pages/PatientFeedback';
import PatientFeedbackDetails from './pages/PatientFeedbackDetails';
import UpdateDoctorPhoto from './pages/UpdateDoctorPhoto';
import ClinicProfile from './pages/ClinicProfile';
import { RecoilRoot } from 'recoil';
import DigitalConsultation from '././pages/DigitalConsultation';
import DigitalConsultationComponent from './components/DigitalConsultationComponent';
import DigitalConsultationV2Component from './components/DigitalConsultationV2Component';
import DigitalConsultationV3Component from './components/DigitalConsultationV3Component';
import PatientsData from './pages/PatientsData';
import IsAuthorized from './components/IsAuthorized';
import DashBoard from './pages/DashBoard'
import PrintEyePrescription from './pages/PrintEyePrescription';
import PatientsLedger from './pages/PatientsLedger';
import { user_role, } from './Store/ConsultationInfo';
import { useRecoilValue, } from 'recoil';
import StaffManagement from './pages/StaffManagement';
// global.baseUrl = 'https://connectedbytes.in/emr-clinic-rest-api-php';
global.ROLE_DOCTOR = "rd";
global.ROLE_CLINIC_ADMIN = "rc";
global.ROLE_DEMOCLINIC_ADMIN = "dc";
global.ROLE_OPD_STAFF = "ros";

function App() {
  // const userRole = useRecoilValue(user_role);
  //let userRole = localStorage.getItem("user_role");

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<IsAuthorized />} />
          {/* {
            userRole === global.ROLE_CLINIC_ADMIN && <Route path="/Home" element={<Home />} />
          } */}
          {/* <Route path="/Home" element={<Home />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />} />
          <Route path="/ResetPassword" element={<ResetPassword />} />
          <Route path="/DoctorRegister" element={<DoctorRegister />} />
          <Route path="/Doctors" element={<Doctors />} />
          <Route path="/EditDoctorProfile" element={<EditDoctorProfile />} />
          <Route path="/AddDoctor" element={<AddDoctor />} />
          <Route path="/AddSlots" element={<AddSlot />} />
          <Route path="/DigitalConsultationV1" element={<DigitalConsultation />} />
          <Route path="/DigitalConsultationComponent" element={<DigitalConsultationComponent />} />
          <Route path="/DigitalConsultationV2" element={<DigitalConsultationV2Component />} />
          <Route path="/DigitalConsultationV3" element={<DigitalConsultationV3Component />} />

          <Route path="/PatientsCheckin" element={<PatientsCheckin />} />
          <Route path="/Consultation" element={<Consultation />} />
          <Route path="/ConsultationHistory" element={<ConsultationHistory />} />
          <Route path="/UploadPatientsRecords" element={<UploadPatientsRecords />} />
          <Route path="/PrintPrescription" element={<PrintPrescription />} />
          <Route path="/PrintEyePrescription" element={<PrintEyePrescription />} />
          <Route path="/Invoice" element={<Invoice />} />
          <Route path="/SelfCheckout" element={<SelfCheckout />} />
          <Route path="/PaperConsultation" element={<PaperConsultation />} />
          <Route path="/CheckoutList" element={<CheckoutList />} />
          <Route path="/Appointment" element={<Appointment />} />
          <Route path="/Payment" element={<Payment />} />
          <Route path="/ViewInvoice" element={<ViewInvoice />} />
          <Route path="/Feemaster" element={<Feemaster />} />
          <Route path="/MoneyReceipt" element={<MoneyReceipt />} />
          <Route path="/FeeMasterList" element={<FeeMasterList />} />
          <Route path="/PatientServiceInvoiceAdd" element={<PatientServiceInvoiceAdd />} />
          <Route path="/PatientInvoiceAdd" element={<PatientInvoiceAdd />} />
          <Route path="/FeeMasterAdd" element={<FeeMasterAdd />} />
          <Route path="/FeeMasterUpdate" element={<FeeMasterUpdate />} />
          <Route path="/PaymentReport" element={<PaymentReport />} />
          <Route path="/FileReader" element={<FileReader />} />
          <Route path="/PatientFeedbackDetails" element={<PatientFeedbackDetails />} />
          <Route path="/ViewAppointments" element={<ViewAppointments />} />
          <Route path="/MyProfile" element={<ClinicProfile />} />
          <Route path="/UpdateDoctorPhoto" element={<UpdateDoctorPhoto />} />
          <Route path="StaffManagement" element={<StaffManagement />} />
          <Route path="/ViewAppointment/:id/:name" element={<ViewAppointment />} />


          <Route path="/ClinicPatients" element={<PatientsData />} />
          <Route path="/error" element={<IsAuthorized />} />
          <Route path="/Ledger" element={<PatientsLedger />} /> */}

          <Route path="/UGF0aWVudEZlZWRiYWNr/:search" element={<PatientFeedback />} />
          <Route path="/CheckinForm" element={<CheckinForm />} />


          {/* <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} /> */}
          {/* <Route path="/MyPatients" element={<MyPatients />} /> */}
          {/* <Route path="/CustomerSupport" element={<CustomerSupport />} /> */}
          {/* <Route path="/NewPatientRegister" element={<NewPatientRegister />} /> */}
          {/* <Route path="/Test" element={<Test />} /> */}
          {/* <Route path="/ConsultationHistoryDetails" element={<ConsultationHistoryDetails />} /> */}
          {/* <Route path="/NewPatientRegisterForDoctor" element={<NewPatientRegisterForDoctor />} /> */}
          {/* <Route path="/Payments" element={<Payments />} /> */}
          {/* <Route path="/Termscondition" element={<Termscondition />} /> */}
          {/* <Route path="/EditSchedule" element={<EditSchedule />} /> */}


        </Routes>
      </Router>
    </div>
  );
}

export default App;
