import React from 'react';
import tickmark from '../mainassets/tickmark.png';

export default (props)=>{
    return(
        <>
        <div className="checkin-popup">
            <img src={tickmark} style={{marginTop:20,height:60,width:60}}/>
            <p style={{fontSize:16,fontWeight:'bold',color:'darkblue',marginTop:10}}>{props.name.name} your form submitted successfully</p>

        </div>
        </>
    )
}