export function getAvailableTimeSlots(startTime, endTime, consultationLength) {
  const startDate = new Date(`2023-01-01T${startTime}`);
  const endDate = new Date(`2023-01-01T${endTime}`);
  const slotDuration = consultationLength * 60 * 1000;
  const availableTimeSlots = [];
  console.log(startDate.getTime() < endDate.getTime());
  for (
    let time = startDate.getTime();
    time < endDate.getTime();
    time += slotDuration
  ) {
    const slotStart = new Date(time);
    const slotEnd = new Date(time + slotDuration);
    availableTimeSlots.push(
      `${slotStart.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })} - ${slotStart.toLocaleTimeString('en-GB', {
        hour: "2-digit",
        minute: "2-digit",
      })} `
    );
  }
  return availableTimeSlots;
}
