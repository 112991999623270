import { createSlice } from "@reduxjs/toolkit";

const AppointmentSlice = createSlice({
  name: "Appointment",
  initialState: {
    currentPage: 0,
    details: {
      doctorName: null,
      doctorId: null,
      userName: null,
      userMobile: null,
      userAge: null,
      slotTiming: null,
    },
  },

  reducers: {
    selectDoctor(state, action) {
      state.details.doctorName = action.payload?.name;
      state.details.doctorId = action.payload?.id;
      state.currentPage = 1;
    },
    selectSlot(state, action) {
      state.details.slotTiming = action.payload;
      state.currentPage = 2;
    },
    addpatientDetails(state, action) {
      state.details.userAge = action.payload.userAge;
      state.details.userName = action.payload.name;
      state.details.userMobile = action.payload.mobile;
      state.currentPage = 3;
    },
    back(state) {
      state.currentPage = state.currentPage - 1;
    },
  },
});

export default AppointmentSlice.reducer;
export const { selectDoctor, selectSlot, addpatientDetails, back } =
  AppointmentSlice.actions;
