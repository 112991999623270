import { configureStore } from "@reduxjs/toolkit";
import ApiSlice from "./ApiSlice";
import AppointmentSlice from "./AppointmentSlice";
import BookingSlice from "./BookingSlice";

const store = configureStore({
  reducer: {
    appointment: AppointmentSlice,
    api: ApiSlice,
    bookings: BookingSlice,
  },
});

export default store;
