import React, { useState, useEffect, useRef } from 'react';
import Header from './Header';
import Footer from './Footer';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import documents from '../mainassets/documents.png';
import '../App.css';
import $ from "jquery";
import { useNavigate, useLocation } from "react-router-dom";
import Axios from 'axios';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { Mic, MicOff } from '@mui/icons-material';
import CustomTopNavbarButton from './CustomTopNavbarButton';
import edit from '../mainassets/edit.png';
import VitalsInformation from './VitalsInformation';
import FamilyHistoryInformation from './FamilyHistoryInformation';
import LifeStyleInformation from './LifeStyleInformation';

import { useRecoilState, useRecoilValue } from 'recoil';
import { life_style_info } from '../Store/ConsultationInfo';
import { consultation_vitals_info } from '../Store/ConsultationInfo';
import { family_history1_info, family_history2_info } from '../Store/ConsultationInfo';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import Select from 'react-select'
import back from '../mainassets/back.png'
const moment = require('moment');



function TableRows({ rows, tableRowRemove, onValUpdate }) {
    return rows.map((rowsData, index) => {
        const { name, dose, when, frequency, duration } = rowsData;
        return (
            <div style={{ marginTop: 7, marginLeft: -15, width: '110%' }}>
                <tr key={index} >
                    <td style={{ width: '30%' }}
                    >
                        <input
                            type="text"
                            value={name}
                            onChange={(event) => onValUpdate(index, event)}
                            name="name"
                            className="form-control"
                            style={{ fontSize: 12, fontWeight: '500' }}
                        />
                    </td>
                    <td style={{ width: '17%' }}>
                        <input
                            type="text"
                            value={dose}
                            onChange={(event) => onValUpdate(index, event)}
                            name="dose"
                            className="form-control"
                            style={{ fontSize: 12, fontWeight: '500' }}

                        />
                    </td>
                    <td style={{ width: '17%' }} >
                        <input
                            type="text"
                            value={when}
                            onChange={(event) => onValUpdate(index, event)}
                            name="when"
                            className="form-control"
                            style={{ fontSize: 12, fontWeight: '500' }}

                        />
                    </td>
                    <td style={{ width: '15%' }}>
                        <input
                            type="text"
                            value={frequency}
                            onChange={(event) => onValUpdate(index, event)}
                            name="frequency"
                            className="form-control"
                            style={{ fontSize: 12, fontWeight: '500' }}

                        />
                    </td>
                    <td style={{ width: '15%' }}>
                        <input

                            type="text"
                            value={duration}
                            onChange={(event) => onValUpdate(index, event)}
                            name="duration"
                            className="form-control"
                            style={{ fontSize: 12, fontWeight: '500' }}

                        />
                    </td>
                    <td style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} >
                        <button
                            style={{ fontSize: 30, marginTop: 5, marginLeft: 55 }} className="btn btn-auto btn-xs btn-danger" onClick={() => tableRowRemove(index)}

                        >-
                        </button>
                    </td>
                </tr>
            </div>

        );
    });
}





export default () => {
    const [user_role, setUser_Role] = useState("");

    let userRole = '';

    const {
        transcript,
        listeningMedicine,
        resetTranscript1,
        browserSupportsSpeechRecognition1
    } = useSpeechRecognition();




    const recognitionF = new window.webkitSpeechRecognition();
    const recognitionM = new window.webkitSpeechRecognition();
    const recognitionL = new window.webkitSpeechRecognition();

    const [micFindings, setMicFindings] = useState(false)
    const [micLab, setMicLab] = useState(false)
    const [micMedicine, setMicMedicine] = useState(false)

    const [medicalSearch, setMedicalSearch] = useState('')
    const investigationCount = useRef(0)
    const adviceDoesCount = useRef(0)
    const adviceDontsCount = useRef(0)
    const [investigationSearch, setInvestigationSearch] = useState('')
    const [suggestionList, setSuggestionList] = useState([])
    const [investigationList, setInvestigationList] = useState([])
    const [ispreviuosRecord, setIsPrevoiusRecord] = useState(false);
    const [docPrescriptions, setDocPrescriptions] = useState([])
    const [basicprofile, setBasicprofile] = useState({});
    const [chief_complaints, setChief_complaints] = useState("");
    const [doctorfindings, setDoctorFindings] = useState("");
    const [historyData, setHistoryData] = useState([])
    const [currentConsultId, setCurrentConsultId] = useState("")
    const [sectionHide, setSectionHide] = useState(true);
    const [consultationId, setConsultationId] = useState("");
    const [isButtonHide, setIsButtonHide] = useState(false);
    const [previousDocuments, setPreviousDocuments] = useState([]);
    const [initialCardHide, setInitialCardHide] = useState(false);
    const [testMed, setTestMed] = useState("");
    const [medName, setMedName] = useState("");
    const [modalOpenVitals, setModalOpenVitals] = useState(false);
    const [modalOpenFamilyHistory, setModalOpenFamilyHistory] = useState(false);
    const [modalOpenLifeStyle, setModalOpenLifeStyle] = useState(false);
    const [locationObject, setLoctionObject] = useState({});
    const [isHistoryAvailable, setIsHistoryAvailable] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [pid, setPid] = useState("");

    const [isFamilyHistory1, setIsFamilyHistory1] = useState(false);
    const [isFamilyHistory2, setIsFamilyHistory2] = useState(false);
    const [familyHistory1, setFamilyHistory1] = useRecoilState(family_history1_info);
    const [familyHistory2, setFamilyHistory2] = useRecoilState(family_history2_info);
    const [docContainerOpen, setDocContainerOpen] = useState(false);
    const [patientListClick, setPatientListClick] = useState(false);
    const [loading, setLoading] = useState(false);
    const [digitalPrescription, setDigitalPrescription] = useState("");
    const [selfOPD, setSelfOPD] = useState("");
    const [isopenDetails, setIsOpenDetails] = useState(false);
    const [adviceDoes, setAdviceDoes] = useState("");
    const [adviceDonts, setAdviceDonts] = useState("");
    const [recoilLifeStyleInfoData, setrecoilLifeStyleInfoData] = useRecoilState(life_style_info);
    const [healthDetails, setHealthDetails] = useState({});
    const [isLifeStyle, setIsLifeStyle] = useState(false);
    const [isHealthDetails, setIsHealthDetails] = useState(false);
    const [adviceDoesList, setAdviceDoesList] = useState([]);
    const [adviceDontsList, setAdviceDontsList] = useState([]);
    const [nextVisit, setNextVisit] = useState("");
    const [showDate, setShowDate] = useState("");
    const [medicineTableRows, setMedicineTableRows] = useState([]);
    const [diagTableRows, setDiagTableRows] = useState([]);
    const [msg, setMsg] = useState("");
    const [consultationModalOpen, setConsultationModalOpen] = useState(false);
    const [cancelConsultationModalOpen, setCancelConsultationModalOpen] = useState(false);
    const [forwardModalOpen, setForwardModalOpen] = useState(false);
    const [showPleaseWait, setShowPleaseWait] = useState(false);
    const [medicineTableArray, setMedicineTableArray] = useState([]);
    const [notes, setNotes] = useState("");
    const [docId, setDocId] = useState("");
    const [patientemail, setPatientEmail] = useState("");
    const [patientsId, setPatientsId] = useState("");
    const [vitalsInfo, setVitalsInfo] = useRecoilState(consultation_vitals_info);
    const [cancelling, setCancelling] = useState(false);
    const [isHidePrescription, setIsHidePrescription] = useState(false);
    const [vaccinationInfo, setVaccinationInfo] = useState([]);
    const [isVaccinationData, setIsVaccinationData] = useState(false);
    const [relation, setRelation] = useState([]);
    const [familyHistoryDisease, setFamilyHistoryDisease] = useState([]);
    const [micFindingsValue, setMicFindingsValue] = useState("");
    const [ConsultationDate, setConsultationDate] = useState("");
    const [serviceList, setServiceList] = useState([]);
    const [servicetype, setServiceType] = useState("");
    const [consultService, setConsultService] = useState("");
    const [ConsultationServiceList, setConsultationServiceList] = useState([]);
    const [isEmptymptyServiceList, setIsEmptyServiceList] = useState(false);
    const [isShowServiceDropDown, setIsShowServiceDropDown] = useState(false);
    const [isChooseServiceType, setIsChooseServiceType] = useState(false);
    const [serviceDocId, setServiceDocId] = useState("");
    const [docDetails, setDocDetails] = useState("");
    const [procedureList, setProcedureList] = useState([]);
    const [procedure, setProcedure] = useState("");
    const [selectedProcedure, setSelectedProcedure] = useState([]);
    const procedureCount = useRef(0)
    const currentdate = new Date().toLocaleDateString('en-GB');
    console.log("procedureArray", JSON.stringify(selectedProcedure));
    const navigate = useNavigate();
    let id = '';

    const location = useLocation();
    global.indexnumber = 0;

    const handleChangeProcedureList = (values) => {
        procedureCount.current = procedureCount.current + 1

        setSelectedProcedure(values || []);
        console.log(".......", values);

        setProcedure(values?.map((list) => ({
            value: list.label,

        })))

    };

    const handleCloseConsultation = (e) => {
        setConsultationModalOpen(false);
    }

    const handleCloseForward = (event) => {
        setForwardModalOpen(false);

    }


    const handleCloseCancelConsultation = (e) => {
        setCancelConsultationModalOpen(false);
    }
    userRole = localStorage.getItem("user_role");
    useEffect(() => {
        if (userRole == global.ROLE_DEMOCLINIC_ADMIN) {
            id = location.state.docid;
        }
        else {
            id = localStorage.getItem("doctor_id");
        }

        console.log("id=  ", id);
        Axios.get(`${global.baseUrl}/api/commonapi/PickList/get_does_advices`).then((response) => {
            console.log("advice does list", response.data);
            if (response.data.status == '1') {
                setAdviceDoesList(response.data.response);
            }


        })
        Axios.get(`${global.baseUrl}/api/commonapi/PickList/get_donts_advices`).then((response) => {
            console.log("advice don'ts list", response.data.response);
            if (response.data.status == '1') {
                setAdviceDontsList(response.data.response);
            }


        })
        Axios.get(`${global.baseUrl}/api/clinicapi/Doctors/get_doctor_details?doctor_id=${id}`).then((response) => {
            console.log("doctor details  ", response.data);
            if (response.data.status == '1') {
                setDocId(response.data.response.id);
                setDigitalPrescription(response.data.response.is_digital_prescription);
                setSelfOPD(response.data.response.is_self_opd);
                setDocDetails(response.data.response);
            }


        });


        console.log("userRole=  ", userRole);
        console.log("location=  ", location.state.state);

        console.log("consultation id=  ", location.state.state.id);
        console.log(" patients id fk =  ", location.state.state.patients_id_fk);
        setUser_Role(userRole);
        setLoading(true);
        console.log(location.state.state.consultation_id)
        setPid(location.state.state.patients_id_fk);
        setConsultationId(location.state.state.consultation_id);
        setCurrentConsultId(location.state.state.id)
        setLoctionObject(location.state.state);

        Axios.get(`${global.baseUrl}/api/clinicapi/PatientConsultation/basicinfo?id=${location.state.state.id}`).then((response) => {
            // console.log(response.data[0].visiting_purpose)
            console.log("basic profile", response.data);
            if (response.data.status == '1') {
                setPatientsId(response.data.response.patients_id_fk);
                setBasicprofile(response.data.response);
                setPatientEmail(response.data.response.email);
                setChief_complaints(response.data.response.visiting_purpose);
                setConsultationDate(moment(response.data.response.checkin_date_time).format('DDMMYYYY'));
                setLoading(false);
            }

            Axios.get(`${global.baseUrl}/api/clinicapi/ConsultationCheckup/life_style?id=${response.data.response.patients_id_fk}`).then((response) => {
                console.log("life style ", response.data);
                if (response.data.status == '1') {
                    if (response.data.response.tobaco != "") {
                        setIsLifeStyle(true);
                        setrecoilLifeStyleInfoData(response.data.response);

                    }
                    else {
                        setIsLifeStyle(false);

                    }
                }


            });


            Axios.get(`${global.baseUrl}/api/clinicapi/ConsultationCheckup/health_details?id=${response.data.response.patients_id_fk}`).then((response) => {
                console.log("health detilas", response.data);
                if (response.data.status == '1') {


                    if (response.data.response != null) {
                        setIsHealthDetails(true);
                        setHealthDetails(response.data.response);
                    }
                    else {
                        setIsHealthDetails(false);

                    }
                }
            });
            Axios.get(`${global.baseUrl}/api/clinicapi/PatientVaccinationInfo/get_vaccination_info_by_patientid?patient_id=${response.data.response.patients_id_fk}`).then((response) => {
                console.log("vaccination info", response.data);
                if (response.data.status == '1') {
                    if (response.data.response.length > 0) {
                        setIsVaccinationData(true);
                        setVaccinationInfo(response.data.response);
                    }
                    else {
                        setIsVaccinationData(false);

                    }
                }

            });



            Axios.get(`${global.baseUrl}/api/clinicapi/ConsultationCheckup/family_history_1?patients_id_fk=${location.state.state.patients_id_fk}`).then((response) => {
                console.log(response.data);
                if (response.data.status == '1') {
                    if (response.data.response.family_history_1_disease != "") {
                        setIsFamilyHistory1(false);
                        setFamilyHistory1(response.data.response);
                        console.log(response.data.response);
                    } else {
                        setIsFamilyHistory1(false);
                    }
                }

                // if (response.data[0].family_history_1_disease != "" && response.data[0].family_history_1_relation != "") {
                //     setIsFamilyHistory1(true);
                //     setFamilyHistory1(response.data);
                //     console.log(response.data);
                // } else {
                //     setIsFamilyHistory1(false);
                // }
            });
            Axios.get(`${global.baseUrl}/api/clinicapi/ConsultationCheckup/family_history_2?patients_id_fk=${location.state.state.patients_id_fk}`).then((response) => {
                console.log(response.data);
                if (response.data.status == '1') {
                    if (response.data.response.family_history_2_disease != "") {
                        setIsFamilyHistory2(true);
                        setFamilyHistory2(response.data.response);
                        console.log(response.data.response);
                    } else {
                        setIsFamilyHistory2(false);
                    }
                }


                // if (response.data[0].family_history_2_disease != "" && response.data[0].family_history_2_relation != "") {
                //     setIsFamilyHistory2(true);
                //     setFamilyHistory2(response.data);
                //     console.log(response.data);
                // }
                // else {
                //     setIsFamilyHistory2(false);
                // }
            });

            Axios.get(`${global.baseUrl}/api/clinicapi/PatientOnboardingData/get?id=${location.state.state.id}`).then((response) => {
                console.log(response.data);
                if (response.data.status = '1') {
                    setVitalsInfo(response.data.response);
                }


            });

            Axios.get(`${global.baseUrl}/api/clinicapi/ConsultationHistory/get_consultation_history?clinic_patient_id=${location.state.state.clinic_patient_id_fk}`).then((response) => {
                console.log(response.data);

                // setCurrentVisitingPurpose(response.data[0].visiting_purpose);
                if (response.data.status == '1') {
                    if (response.data.response.length > 0) {

                        setHistoryData(response.data.response);
                        setIsHistoryAvailable(true);

                    } else {
                        setIsHistoryAvailable(false);
                    }
                }

            });

        });
        Axios.get(`${global.baseUrl}/api/commonapi/PickList/get_family_ralation`).then((response) => {
            console.log(response.data);
            if (response.data.status == '1') {
                setRelation(response.data.response);
            }
        });
        Axios.get(`${global.baseUrl}/api/commonapi/PickList/get_family_disease`).then((response) => {
            console.log(response.data);
            if (response.data.status == '1') {
                setFamilyHistoryDisease(response.data.response);
            }
        });

        Axios.get(`${global.baseUrl}/api/commonapi/PickList/get_services`
        ).then(
            (response) => {

                console.log("service list = ", response.data.response);
                setServiceList(response.data.response);

            }

        ).catch((error) => {
            console.log(error.data);
        });

        // Axios.get(`${global.baseUrl}/api/commonapi/PickList/get_procedure_list`
        // ).then(
        //     (response) => {

        //         console.log("procedure_list = ", response.data.response);
        //         setProcedureList(response.data.response);

        //     }

        // ).catch((error) => {
        //     console.log(error.data);
        // });
        Axios.get(`${global.baseUrl}/api/clinicapi/FeeMaster/get_services_list?serviceType=pro&clinicId=${localStorage.getItem("clinic_id")}`).then((response) => {
            console.log(response.data.response);
            if (response.data.response.length > 0) {
                const formattedList = response.data.response?.map((list) => ({
                    value: list.id,
                    label: list.fee_name,
                }))
                setProcedureList(formattedList);
            }
            else {
                setProcedureList([]);
            }
        });

    }, []);

    const backToPrescriptionHandler = () => {
        setIsHidePrescription(false);

    }


    function startListeningLab() {
        setMicLab(true);
        SpeechRecognition.stopListening();
        recognitionF.stop();
        setMicFindings(false);

        recognitionL.interimResults = true;
        recognitionL.continuous = true;

        recognitionL.start();
        setTimeout(() => {
            recognitionL.stop()
            setMicLab(false)
            console.log("Stop")

        }, 3500);


    }
    function startListeningMedicine() {

        // if (listeningMedicine) {
        //     setMicMedicine(false)
        //     SpeechRecognition.stopListening()
        // } else {
        //     setMicMedicine(true)
        //     SpeechRecognition.startListening()
        // }

        setMicMedicine(true)
        recognitionF.stop()
        recognitionL.stop()
        setMicFindings(false);
        SpeechRecognition.startListening()
        setTimeout(() => {
            SpeechRecognition.stopListening()
            setMicMedicine(false)
        }, 5000);

    }

    function startListeningFindings() {
        setMicFindings(true);
        recognitionL.stop();
        SpeechRecognition.stopListening();
        recognitionF.interimResults = true;
        recognitionF.continuous = true;

        recognitionF.start();
        if (micFindings) {
            recognitionF.stop()
            setMicFindings(false)
            console.log("Stop");
        }
    }


    useEffect(() => {
        // setMicMedicine(false);
        setMicFindings(false);
        setMedicineSearchHandler(transcript);
    }, [transcript])

    recognitionL.onresult = (event) => {
        console.log("listening");

        const value = event.results[0][0].transcript
        setInvestigationHandler(value)
        console.log(value)

        //  setRecognizedText(event.results[0][0].transcript);
    };
    recognitionF.onresult = (event) => {
        console.log("listening");
        const value = event.results[0][0].transcript
        console.log("listening =", value)
        setDoctorFindingsHandler(doctorfindings + '\n' + value);
        setMicFindingsValue(value);
        //  setRecognizedText(event.results[0][0].transcript);
    };


    const cancelConsultation = (e) => {
        e.preventDefault();
        setCancelling(true);

        Axios.get(`${global.baseUrl}/api/clinicapi/ConsultationCheckup/cancel_consultation?consultation_id=${location.state.state.id}`).then((response) => {
            console.log("data", response.data);
            if (response.data.status == '1') {
                if (response.data.response) {
                    setCancelling(false);
                    navigate("/Consultation");
                }
            }
        })

    }
    const [audioRecFile, setAudioRecFile] = useState("");
    const previousRecordHandler = (constid) => {
        setIsOpenDetails(true);
        setPatientListClick(true);
        setModalOpen(true);
        if (patientListClick == true) {
            Axios.get(`${global.baseUrl}/api/clinicapi/ConsultationHistory/get_consultation_history_details?id=${constid}`).then((response) => {
                console.log(constid)
                if (response.data.status == '1') {
                    setDocPrescriptions(response.data.response)
                    setDocContainerOpen(true);
                    console.log(response.data.response)
                    Axios.get(`${global.baseUrl}/api/commonapi/File_reader/audio_read_file_content?clinicid=${localStorage.getItem("clinic_id")}&constid=${response.data.response[0].id}&file=${response.data.response[0].audio}`).then((response) => {
                        console.log("buffer file = ", response.data.response);
                        setAudioRecFile(response.data.base64String);
                    });
                }

            });
        }

        Axios.get(`${global.baseUrl}/api/clinicapi/ConsultationHistory/get_previous_documents?consultation_id=${constid}`).then((response) => {
            console.log(response.data)
            if (response.data.status == '1') {
                if (response.data.response.length > 0) {
                    setPreviousDocuments(response.data.response)
                    setIsPrevoiusRecord(true)

                } else {
                    setIsPrevoiusRecord(false)

                }
            }

        });


    }


    const chiefComplaintsHandler = (e) => {

        setChief_complaints(e.target.value);

    }

    const setDoctorFindingsHandler = async (value) => {

        // setDoctor_notes(value);
        setDoctorFindings(value);
    }
    const testMedHandler = (e) => {

        setTestMed(e.target.value);

    }

    const adviceDoesHandler = (e) => {
        setAdviceDoes(e.target.value);

    }
    const adviceDontsHandler = (e) => {
        setAdviceDonts(e.target.value);

    }
    const notesChangeHandler = (e) => {
        setNotes(e.target.value);

    }
    const serviceUpdateHandler = (e) => {
        setConsultService(e.target.value);
        console.log(e.target.value);

        Axios.get(`${global.baseUrl}/api/clinicapi/FeeMaster/get_service_attached_doctor_details?serviceid=${e.target.value}`).then((response) => {
            console.log(response.data.response);
            setServiceDocId(response.data.response.doctor_id_fk);

        });


    }
    const serviceTypeUpdateHandler = (e) => {
        console.log(e.target.value)
        setServiceType(e.target.value);
        if (e.target.value != "") {
            setIsChooseServiceType(true);

        } else {
            setIsChooseServiceType(false);
        }
        Axios.get(`${global.baseUrl}/api/clinicapi/FeeMaster/get_services_list?serviceType=${e.target.value}&clinicId=${localStorage.getItem("clinic_id")}`).then((response) => {
            console.log(response.data.response);
            if (response.data.response.length > 0) {
                setConsultationServiceList(response.data.response);
                setIsEmptyServiceList(false);
                setIsShowServiceDropDown(true);

            }
            else {
                setIsEmptyServiceList(true);
                setIsShowServiceDropDown(false);

            }

        });

    }

    const addServiceHandler = (e) => {
        e.preventDefault();
        console.log("serviceDocId= ", serviceDocId)
        setIsButtonHide(true);
        e.preventDefault();
        let formdata = $(e.target);
        formdata = new FormData();
        formdata.append('clinic_id', localStorage.getItem("clinic_id"));
        formdata.append('service_id', consultService);
        formdata.append('consultation_id_fk', location.state.state.id);
        formdata.append('doctors_id_fk', serviceDocId);

        $.ajax({
            type: "POST",
            url: `${global.baseUrl}/api/clinicapi/FeeMaster/add_consultation_service`,
            data: formdata,
            processData: false,
            contentType: false,
            success(data) {
                console.log("service data=  ", data);
                setForwardModalOpen(false);
                navigate("/Consultation")

            },
            error: function (err) {
                console.log(err.responseText);
                alert("error has occured  ", err);
            }
        });
    }

    const setMedicineSearchHandler = async (value) => {
        setMedicalSearch(value);
        if (value === '' || value.length < 2) {
            setSuggestionList([])
            return
        }
        try {
            const response = await Axios.get(`${global.baseUrl}/api/commonapi/PickList/get_medicine_details?name=${value}`)
            if (response.data.status == '1') {
                setSuggestionList(response.data.response)
            }

        } catch (error) {
            console.log(error)
        }

    }

    const setInvestigationHandler = async (value) => {
        setInvestigationSearch(value)
        if (value === '' || value.length < 2) {
            setInvestigationList([])
            return
        }
        try {
            const response = await Axios.get(`${global.baseUrl}/api/commonapi/PickList/get_diagnostictest?name=${value}`)
            console.log(response)
            if (response.data.status == '1') {
                setInvestigationList(response.data.response)
            }

        } catch (error) {
            console.log(error)
        }
    }


    const nextVisitHandler = (e) => {
        setNextVisit(e.target.value);
        console.log(e.target.value);

        if (e.target.value == "After 1 week") {
            console.log("1 week");

            let m_names = new Array("Jan", "Feb", "Mar",
                "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec");
            let d = new Date();
            d.setDate(d.getDate() + 7);
            let curr_date = d.getDate();
            let curr_month = d.getMonth();
            let curr_year = d.getFullYear();
            const language = 'en-us';
            const options = { weekday: 'long' };
            let nextvisitday = curr_date + "-" + m_names[curr_month] + "-" + curr_year + " - " + d.toLocaleString(language, options);
            console.log(nextvisitday);
            setShowDate(nextvisitday);

        }
        if (e.target.value == "After 2 weeks") {
            console.log("2 weeks");

            let m_names = new Array("Jan", "Feb", "Mar",
                "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec");
            let d = new Date();
            d.setDate(d.getDate() + 14);
            let curr_date = d.getDate();
            let curr_month = d.getMonth();
            let curr_year = d.getFullYear();
            const language = 'en-us';
            const options = { weekday: 'long' };
            let nextvisitday = curr_date + "-" + m_names[curr_month] + "-" + curr_year + " - " + d.toLocaleString(language, options);
            console.log(nextvisitday);
            setShowDate(nextvisitday);

        }
        if (e.target.value == "After 1 month") {
            console.log("1 month");

            let m_names = new Array("Jan", "Feb", "Mar",
                "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec");
            let d = new Date();
            d.setDate(d.getDate() + 30);
            let curr_date = d.getDate();
            let curr_month = d.getMonth();
            let curr_year = d.getFullYear();
            const language = 'en-us';
            const options = { weekday: 'long' };
            let nextvisitday = curr_date + "-" + m_names[curr_month] + "-" + curr_year + " - " + d.toLocaleString(language, options);
            console.log(nextvisitday);
            setShowDate(nextvisitday);

        }
        if (e.target.value == "After 3 months") {
            console.log("3 months");

            let m_names = new Array("Jan", "Feb", "Mar",
                "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec");
            let d = new Date();
            d.setDate(d.getDate() + 90);
            let curr_date = d.getDate();
            let curr_month = d.getMonth();
            let curr_year = d.getFullYear();
            const language = 'en-us';
            const options = { weekday: 'long' };
            let nextvisitday = curr_date + "-" + m_names[curr_month] + "-" + curr_year + " - " + d.toLocaleString(language, options);
            console.log(nextvisitday);
            setShowDate(nextvisitday);

        }
        if (e.target.value == "After 6 months") {
            console.log("6 months");

            let m_names = new Array("Jan", "Feb", "Mar",
                "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec");
            let d = new Date();
            d.setDate(d.getDate() + 120);
            let curr_date = d.getDate();
            let curr_month = d.getMonth();
            let curr_year = d.getFullYear();
            const language = 'en-us';
            const options = { weekday: 'long' };
            let nextvisitday = curr_date + "-" + m_names[curr_month] + "-" + curr_year + " - " + d.toLocaleString(language, options);
            console.log(nextvisitday);
            setShowDate(nextvisitday);

        }

    }


    let nextVisitDayList = [

        {
            id: 1,
            key: 'After 1 week',
            value: 'After 1 week',

        },
        {
            id: 2,
            key: 'After 2 weeks',
            value: 'After 2 weeks',

        },
        {
            id: 3,
            key: 'After 1 month',
            value: 'After 1 month',

        },
        {
            id: 4,
            key: 'After 3 months',
            value: 'After 3 months',

        },
        {
            id: 5,
            key: 'After 6 months',
            value: 'After 6 months',

        },
    ]


    const openNewTabHandler = (file) => {
        const url = `${file}`;
        window.open(url, "toolbar=yes,scrollbars=yes,resizable=yes,width=400,height=400");
    }

    const completeWithAudioHandler = (e) => {

        e.preventDefault();

        if (selectedProcedure.length > 0) {
            let formdata = $(e.target);
            formdata = new FormData();
            formdata.append('clinic_id', localStorage.getItem("clinic_id"));
            formdata.append('array_data', JSON.stringify(selectedProcedure));
            formdata.append('consultation_id_fk', location.state.state.id);

            $.ajax({
                type: "POST",
                url: `${global.baseUrl}/api/clinicapi/FeeMaster/add_pro_consultation_service`,
                data: formdata,
                processData: false,
                contentType: false,
                success(data) {
                    console.log("service data=  ", data);

                },
                error: function (err) {
                    console.log(err.responseText);
                    alert("error has occured  ", err);
                }
            });
        }
        let formdata = $(e.target);
        formdata = new FormData();
        formdata.append("file", audioFile);
        formdata.append('constid', location.state.state.id);
        formdata.append('clinicid', localStorage.getItem("clinic_id"));
        $.ajax({
            type: "POST",
            url: `${global.baseUrl}/api/commonapi/FileUpload/audio_file_insert_emr`,
            data: formdata,
            processData: false,
            contentType: false,
            success(data) {
                console.log(data);
                setIsButtonHide(true);
                e.preventDefault();
                setShowPleaseWait(true);
                let formdata = $(e.target);
                formdata = new FormData();
                formdata.append('prescription_id_fk', "1");
                formdata.append('chief_complaints', chief_complaints);
                formdata.append('doctor_findings', doctorfindings);
                formdata.append('notes', notes);
                formdata.append('doctor_investigation', testMed);
                formdata.append('medicine', medicineTableArray);
                formdata.append('advice_does', adviceDoes);
                formdata.append('advice_donts', adviceDonts);
                formdata.append('next_visit', showDate);
                if (chief_complaints && doctorfindings && testMed && medName != "") {
                    formdata.append('isdigital_prescription', "1");
                }
                else {
                    formdata.append('isdigital_prescription', "0");
                }

                formdata.append('id', consultationId);
                formdata.append('patients_id_fk', pid);
                formdata.append('audio', data);
                if (selectedProcedure.length > 0) {
                    formdata.append('clinic_procedure', JSON.stringify(procedure));
                }

                $.ajax({
                    type: "POST",
                    url: `${global.baseUrl}/api/clinicapi/ConsultationCheckup/complete_consultation`,
                    data: formdata,
                    processData: false,
                    contentType: false,
                    success(data) {
                        console.log(data);

                        if (pid != '0') {


                            let formdata = $(e.target);
                            formdata = new FormData();
                            formdata.append('consultation_id', currentConsultId);
                            formdata.append('patient_name', location.state.state.name);
                            formdata.append('doctors_id', docId);
                            formdata.append('clinic_id', localStorage.getItem("clinic_id"));
                            $.ajax({
                                type: "POST",
                                url: `${global.baseUrl}/api/clinicapi/Feedback/create_feedback`,
                                data: formdata,
                                processData: false,
                                contentType: false,
                                success(feedbackId) {
                                    console.log(feedbackId);
                                    const datafile = new FormData();
                                    datafile.append("email_id", patientemail);
                                    datafile.append("subject", " Healthconnectpro Feedback");
                                    let base64encodedParams = `${feedbackId.response}&${docId}&${ConsultationDate}`;
                                    let url = `${window.location.host}/UGF0aWVudEZlZWRiYWNr/${base64_encode(base64encodedParams)}`;
                                    console.log("url", url);
                                    // let f = 25;
                                    // let d = 17;
                                    // let base64encodedParams2 = `${f}&${d}&12112023`;
                                    // let url1 = `${window.location.host}/UGF0aWVudEZlZWRiYWNr?${base64_encode(base64encodedParams2)}`;
                                    // let base64encodedParams2 = `feedbackid=16&doctorid=23&11112023`;
                                    // let url1 = `${window.location.host}/UGF0aWVudEZlZWRiYWNr?${base64_encode(base64encodedParams2)}`;
                                    // console.log(window.btoa(`${f}&${d}&23122023`));
                                    // console.log(window.atob(`MTMwJjExNCYyMzEyMjAyMw==`));
                                    datafile.append("message", `${url}`);
                                    $.ajax({
                                        type: "POST",
                                        url: `${global.baseUrl}/api/clinicapi/SendMail/send_mail_basic`,
                                        data: datafile,
                                        processData: false,
                                        contentType: false,
                                        success(data) {
                                            console.log("emailSendHandler:: msg sent  ", data);

                                            Axios.get(`${global.baseUrl}/api/clinicapi/ConsultationCheckup/get_AI_consultationId?constId=${location.state.state.consultation_id}`).then((response) => {
                                                console.log(response.data);
                                                if (response.data.status == '1') {
                                                    setShowPleaseWait(false);

                                                    setMsg("Consultation Complete")
                                                    setTimeout(() => {
                                                        navigate("/SelfCheckout", { state: response.data.response.id })
                                                    }, 2000)
                                                }


                                            })

                                        },
                                        error: function (err) {
                                            console.log(err.responseText);
                                            alert("error has occured  ", err);
                                        }
                                    });

                                },
                                error: function (err) {
                                    console.log(err);
                                    alert("Feedback creation failed ", err);

                                }
                            });
                        }
                        else {
                            Axios.get(`${global.baseUrl}/api/clinicapi/ConsultationCheckup/get_AI_consultationId?constId=${location.state.state.consultation_id}`).then((response) => {
                                console.log(response.data);
                                if (response.data.status == '1') {
                                    setShowPleaseWait(false);

                                    setMsg("Consultation Complete")
                                    setTimeout(() => {
                                        navigate("/SelfCheckout", { state: response.data.response.id })
                                    }, 2000)
                                }


                            })
                        }



                        // }

                    },
                    error: function (err) {
                        console.log(err.responseText);
                        setIsButtonHide(false);
                        setShowPleaseWait(false);
                        alert("error has occured  ", err.responseText);
                    }
                });

            },
            error: function (err) {
                console.log(err);

            }
        });

    }


    const completeHandler = (e) => {

        e.preventDefault();
        console.log(medicineTableArray);
        if (selectedProcedure.length > 0) {
            let formdata = $(e.target);
            formdata = new FormData();
            formdata.append('clinic_id', localStorage.getItem("clinic_id"));
            formdata.append('array_data', JSON.stringify(selectedProcedure));
            formdata.append('consultation_id_fk', location.state.state.id);

            $.ajax({
                type: "POST",
                url: `${global.baseUrl}/api/clinicapi/FeeMaster/add_pro_consultation_service`,
                data: formdata,
                processData: false,
                contentType: false,
                success(data) {
                    console.log("service data=  ", data);

                },
                error: function (err) {
                    console.log(err.responseText);
                    alert("error has occured  ", err);
                }
            });
        }
        setIsButtonHide(true);
        e.preventDefault();
        setShowPleaseWait(true);
        let formdata = $(e.target);
        formdata = new FormData();
        formdata.append('prescription_id_fk', "1");
        formdata.append('chief_complaints', chief_complaints);
        formdata.append('doctor_findings', doctorfindings);
        formdata.append('notes', notes);
        formdata.append('doctor_investigation', testMed);
        formdata.append('medicine', medicineTableArray);
        formdata.append('advice_does', adviceDoes);
        formdata.append('advice_donts', adviceDonts);
        formdata.append('next_visit', showDate);
        if (chief_complaints && doctorfindings && testMed && medName != "") {
            formdata.append('isdigital_prescription', "1");
        }
        else {
            formdata.append('isdigital_prescription', "0");
        }

        formdata.append('id', consultationId);
        formdata.append('patients_id_fk', pid);
        formdata.append('audio', audioName);
        if (selectedProcedure.length > 0) {
            formdata.append('clinic_procedure', JSON.stringify(procedure));
        }
        $.ajax({
            type: "POST",
            url: `${global.baseUrl}/api/clinicapi/ConsultationCheckup/complete_consultation`,
            data: formdata,
            processData: false,
            contentType: false,
            success(data) {
                console.log(data);

                if (pid != '0') {


                    let formdata = $(e.target);
                    formdata = new FormData();
                    formdata.append('consultation_id', currentConsultId);
                    formdata.append('patient_name', location.state.state.name);
                    formdata.append('doctors_id', docId);
                    formdata.append('clinic_id', localStorage.getItem("clinic_id"));
                    $.ajax({
                        type: "POST",
                        url: `${global.baseUrl}/api/clinicapi/Feedback/create_feedback`,
                        data: formdata,
                        processData: false,
                        contentType: false,
                        success(feedbackId) {
                            console.log(feedbackId);
                            const datafile = new FormData();
                            datafile.append("email_id", patientemail);
                            datafile.append("subject", " Healthconnectpro Feedback");
                            let base64encodedParams = `${feedbackId.response}&${docId}&${ConsultationDate}`;
                            let url = `${window.location.host}/UGF0aWVudEZlZWRiYWNr/${base64_encode(base64encodedParams)}`;
                            console.log("url", url);
                            // let f = 25;
                            // let d = 17;
                            // let base64encodedParams2 = `${f}&${d}&12112023`;
                            // let url1 = `${window.location.host}/UGF0aWVudEZlZWRiYWNr?${base64_encode(base64encodedParams2)}`;
                            // let base64encodedParams2 = `feedbackid=16&doctorid=23&11112023`;
                            // let url1 = `${window.location.host}/UGF0aWVudEZlZWRiYWNr?${base64_encode(base64encodedParams2)}`;
                            // console.log(window.btoa(`${f}&${d}&23122023`));
                            // console.log(window.atob(`MTMwJjExNCYyMzEyMjAyMw==`));
                            datafile.append("message", `${url}`);
                            $.ajax({
                                type: "POST",
                                url: `${global.baseUrl}/api/clinicapi/SendMail/send_mail_basic`,
                                data: datafile,
                                processData: false,
                                contentType: false,
                                success(data) {
                                    console.log("emailSendHandler:: msg sent  ", data);

                                    Axios.get(`${global.baseUrl}/api/clinicapi/ConsultationCheckup/get_AI_consultationId?constId=${location.state.state.consultation_id}`).then((response) => {
                                        console.log(response.data);
                                        if (response.data.status == '1') {
                                            setShowPleaseWait(false);

                                            setMsg("Consultation Complete")
                                            setTimeout(() => {
                                                navigate("/SelfCheckout", { state: response.data.response.id })
                                            }, 2000)
                                        }
                                    })
                                },
                                error: function (err) {
                                    console.log(err.responseText);
                                    alert("error has occured  ", err);
                                }
                            });

                        },
                        error: function (err) {
                            console.log(err);
                            alert("Feedback creation failed ", err);

                        }
                    });
                }
                else {
                    Axios.get(`${global.baseUrl}/api/clinicapi/ConsultationCheckup/get_AI_consultationId?constId=${location.state.state.consultation_id}`).then((response) => {
                        console.log(response.data);
                        if (response.data.status == '1') {
                            setShowPleaseWait(false);

                            setMsg("Consultation Complete")
                            setTimeout(() => {
                                navigate("/SelfCheckout", { state: response.data.response.id })
                            }, 2000)
                        }
                    })
                }

            },
            error: function (err) {
                console.log(err.responseText);
                setIsButtonHide(false);
                setShowPleaseWait(false);
                alert("error has occured  ", err.responseText);
            }
        });

    }

    const handleModalCloseVitals = () => {
        setModalOpenVitals(false);
        // window.location.reload(false);

    };

    const openVitals = () => {
        setModalOpenVitals(true);

    }
    const handleModalCloseFamilyHistory = () => {
        setModalOpenFamilyHistory(false);
        // window.location.reload(false);

    };

    const openFamilyHistory = () => {
        setModalOpenFamilyHistory(true);

    }
    const handleModalCloseLifeStyle = () => {
        setModalOpenLifeStyle(false);

    };

    const openLifeStyle = () => {
        setModalOpenLifeStyle(true);

    }

    const consultationHistoryHandler = () => {
        setModalOpen(true);

    }

    const backToListHandler = () => {
        navigate("/Consultation");
    }

    const healthInformationHandler = () => {
        setIsHidePrescription(true);

    }

    const backHandler = () => {
        setInitialCardHide(false);
        setSectionHide(true)
        setIsPrevoiusRecord(false)

    }

    const handleClose = () => {
        setModalOpen(false);

    };
    const onAdviceDoesUpdate = (e) => {
        // setAdviceDoes(e.target.value);
        adviceDoesCount.current = adviceDoesCount.current + 1
        const old = adviceDoes
        setInvestigationSearch('')
        setAdviceDoes(old + adviceDoesCount.current + ':) ' + e.target.value + '\n')
        // setAdviceDoesList([]);

    }

    const onAdviceDontsUpdate = (e) => {
        adviceDontsCount.current = adviceDontsCount.current + 1
        const old = adviceDonts
        setInvestigationSearch('')
        setAdviceDonts(old + adviceDontsCount.current + ':) ' + e.target.value + '\n')
    }


    let array_data;
    const onValUpdate = (i, event) => {
        const { name, value } = event.target;
        const data = [...medicineTableRows];
        data[i][name] = value;
        console.log("data array, ", data);
        console.log("index, ", data[i]);
        // console.log("medicine, ", data[i].medicine);
        setMedicineTableRows(data);
        array_data = [JSON.stringify(data)];
        setMedicineTableArray(array_data);
    };
    const tableRowRemove = (index) => {
        const dataRow = [...medicineTableRows];
        dataRow.splice(index, 1);
        setMedicineTableRows(dataRow);
    };

    const addRowTable = () => {

        const data = {
            name: "",
            dose: "",
            when: "",
            frequency: "",
            duration: ""
        };
        setMedicineTableRows([...medicineTableRows, data]);
    };

    const addRowDiagnostic = () => {

        const data = {
            name: "",
            when: ""
        };
        setDiagTableRows([...diagTableRows, data]);

    }

    const addRowTableWithData = (medName, medDose, medFreq) => {

        const data = {
            name: medName,
            dose: medDose,
            when: "",
            frequency: "",
            duration: medFreq
        };
        setMedicineTableRows([...medicineTableRows, data]);
        // const value = [...medicineTableRows];

        array_data = [JSON.stringify([...medicineTableRows, data])];
        setMedicineTableArray(array_data);
    };

    const audioChunk = useRef([]);
    const [recording, setRecording] = useState("");
    const mediaRecorderRef = useRef(null);
    const [audioFile, setAudioFile] = useState("");
    const [audioRecordStart, setAudioRecordStart] = useState(false);
    const [audioName, setAudioName] = useState("");
    const startRec = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true })

            const mediaRecorder = new MediaRecorder(stream);
            mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    audioChunk.current.push(event.data);
                };
            };
            mediaRecorder.onstop = () => {
                const audioBlob = new Blob(audioChunk.current, { type: 'audio/mp3' });
                const audiofile = new File([audioBlob], "audiofile.mp3", {
                    type: "audio/mpeg",
                });
                setAudioFile(audiofile);
                console.log(audiofile);
                const audioUrl = URL.createObjectURL(audiofile);
                console.log(audioUrl)
                setRecording(audioUrl);
            };
            mediaRecorderRef.current = mediaRecorder;
            mediaRecorder.start();
            setAudioRecordStart(true);
        }
        catch (error) { console.error('Error accessing microphone:', error) };

    };

    const stopRec = () => {
        setAudioRecordStart(false)
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
            mediaRecorderRef.current.stop();
        }
    }

    const audioUploadHandeler = (e) => {
        e.preventDefault();
        let formdata = $(e.target);
        formdata = new FormData();
        formdata.append("file", audioFile);
        formdata.append('constid', location.state.state.id);
        formdata.append('clinicid', localStorage.getItem("clinic_id"));
        $.ajax({
            type: "POST",
            url: `${global.baseUrl}/api/commonapi/FileUpload/audio_file_insert_emr`,
            data: formdata,
            processData: false,
            contentType: false,
            success(data) {
                console.log(data);
                setAudioName(data);
            },
            error: function (err) {
                console.log(err);

            }
        });
    }
    return (
        <div className="page-user" onClick={() => {
            setSuggestionList([])
            setInvestigationList([])
        }}>
            <div className="topbar-wrap">
                <Header />

            </div>

            {/* <div style={{ marginTop: -15 }}>
                <CustomTopNavbarButton />
            </div> */}
            <div style={{ marginTop: -20, marginBottom: 20 }} className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4">
                            <div style={{ textAlign: 'left' }}>
                                <button className="btn btn-auto btn-primary" onClick={() => { navigate(-1) }}  ><img src={back} style={{ height: 20, width: 20 }} /></button>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <h6 className="card-title">Doctor's Consultation ({docDetails.first_name} {docDetails.last_name})</h6>
                        </div>
                        <div className="col-xl-2 col-lg-2">
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ marginTop: -5 }} className="page-content">
                <div className="container">


                    <div className="row">

                        <div className="main-content col-lg-12">



                            <div className="content-area card">
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                                    <div className="col-lg-7">
                                        <div style={{ textAlign: 'right' }}>
                                            <p className="card-title"> {location.state.state.name} &nbsp;(&nbsp;{location.state.state.gender} {location.state.state.age} )</p>

                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            {
                                                isHidePrescription == false ?
                                                    <>
                                                        <button onClick={consultationHistoryHandler} type="submit" className="btn btn-auto   btn-primary" >Consultation History</button>
                                                        <button style={{ marginRight: 15 }} onClick={healthInformationHandler} type="submit" className="btn btn-auto   btn-success" >Health Information</button>

                                                    </>
                                                    :
                                                    <div style={{ textAlign: 'right' }}>
                                                        <button onClick={backToPrescriptionHandler} type="submit" className="btn btn-auto   btn-primary" >⬅ Back to Prescription</button>
                                                    </div>
                                            }

                                        </div>

                                    </div>

                                </div>
                                <div className="card-innr">

                                    <div style={{ display: 'flex', flexDirection: 'row' }}>

                                        <div style={{ width: '33%', textAlign: 'left' }}>
                                            <p style={{ color: '#5A8AFF' }} className="label">Height(ft): </p>
                                        </div>

                                        <div style={{ width: '17%', textAlign: 'left' }}>
                                            <p style={{ fontSize: 15, marginTop: 2 }} className="value">{vitalsInfo.height} </p>
                                        </div>

                                        <div style={{ width: '33%', textAlign: 'left' }}>
                                            <p style={{ color: '#5A8AFF' }} className="label">Weight(kg):  </p>
                                        </div>

                                        <div style={{ width: '17%', textAlign: 'left' }}>
                                            <p style={{ fontSize: 15, marginTop: 2 }} className="value">{vitalsInfo.weight}  </p>
                                        </div>

                                        <div style={{ width: '33%', textAlign: 'left' }}>
                                            <p style={{ color: '#5A8AFF' }} className="label">Bp(mmHg): </p>
                                        </div>

                                        <div style={{ width: '17%', textAlign: 'left' }}>
                                            {vitalsInfo.blood_pressure_mm != "0" ?
                                                <p style={{ fontSize: 15, marginTop: 2 }} className="value">{vitalsInfo.blood_pressure_mm}/{vitalsInfo.blood_pressure_hg} </p>
                                                : null}
                                        </div>

                                        <div style={{ width: '33%', textAlign: 'left' }}>
                                            <p style={{ color: '#5A8AFF' }} className="label">Pulse Rate:  </p>
                                        </div>

                                        <div style={{ width: '17%', textAlign: 'left' }}>
                                            <p style={{ fontSize: 15, marginTop: 2 }} className="value">{vitalsInfo.pulse_rate} </p>
                                        </div>

                                        {/* </div> */}

                                        {/* <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}> */}
                                        <div style={{ width: '33%', textAlign: 'left' }}>
                                            <p style={{ color: '#5A8AFF' }} className="label">Blood Sugar:  </p>
                                        </div>

                                        <div style={{ width: '17%', textAlign: 'left' }}>
                                            <p style={{ fontSize: 15, marginTop: 2 }} className="value">{vitalsInfo.blood_sugar_random}  </p>
                                        </div>

                                        <div style={{ width: '33%', textAlign: 'left' }}>
                                            <p style={{ color: '#5A8AFF' }} className="label">Temperature: </p>
                                        </div>

                                        <div style={{ width: '17%', textAlign: 'left' }}>
                                            <p style={{ fontSize: 15, marginTop: 2 }} className="value">{vitalsInfo.temperature} </p>
                                        </div>

                                        <div style={{ width: 100 }}>
                                            {
                                                location.state.state.patients_id_fk != 0 ?
                                                    <img style={{ height: 25, width: 25, }} src={edit} onClick={openVitals} />
                                                    : null

                                            }
                                        </div>

                                    </div>

                                    {isHidePrescription == false ?
                                        <>

                                            <div style={{ marginTop: 20 }} className="row">
                                                <div className="col-md-6">
                                                    <p style={{ textAlign: 'left', color: '#5A8AFF' }} className="label">Chief Complaints:</p>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', marginTop: 15 }}>

                                                        <textarea style={{ height: 120, fontSize: 14, fontWeight: '500', color: 'black' }} className="input-bordered input-textarea"
                                                            value={chief_complaints} name="chief_complaints" onChange={(e) => chiefComplaintsHandler(e)}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <p style={{ textAlign: 'left', color: '#5A8AFF' }} className="label">Record Audio:</p>
                                                    <div className="row">
                                                        {
                                                            audioRecordStart == false ?
                                                                <p type="submit" onClick={startRec} style={{ marginLeft: 150, color: 'blue', cursor: 'pointer', fontSize: 18 }}  >Start </p>
                                                                : <p type="submit" onClick={stopRec} style={{ marginLeft: 150, color: 'red', cursor: 'pointer', fontSize: 18 }}  >Stop </p>
                                                        }

                                                        {
                                                            audioFile ?
                                                                <p onClick={audioUploadHandeler} style={{ marginLeft: 170, color: 'green', cursor: 'pointer', fontSize: 18 }}>
                                                                    Upload
                                                                </p> : null
                                                        }
                                                    </div>

                                                    <div style={{ marginTop: 10 }}>
                                                        {recording ?
                                                            <>
                                                                <audio src={recording} style={{ marginLeft: 30, border: 2 }} controls />
                                                            </>

                                                            : <audio src={recording} style={{ marginLeft: 30, border: 2 }} controls />

                                                        }
                                                    </div>


                                                </div>
                                            </div>

                                            <div style={{ marginTop: 20 }} className="row">
                                                <div className="col-md-6">
                                                    <p style={{ textAlign: 'left', color: '#5A8AFF' }} className="label">Findings:</p>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', marginTop: 15 }}>
                                                        <textarea style={{ height: 120, fontSize: 14, fontWeight: '500', color: 'black' }} className="input-bordered input-textarea"
                                                            value={doctorfindings} name="doctorfindings" onChange={(e) => setDoctorFindingsHandler(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div >
                                                        <p style={{ textAlign: 'left', color: '#5A8AFF' }} className="label">Notes:</p>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', marginTop: 15 }}>
                                                            <textarea style={{ height: 120, fontSize: 14, fontWeight: '500', color: 'black' }} className="input-bordered input-textarea"
                                                                value={notes} name="notes" onChange={(e) => notesChangeHandler(e)}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                onClick={() => {

                                                    startListeningFindings();

                                                }}
                                                style={{ marginTop: 20, paddingRight: 30 }}>
                                                {
                                                    <Mic style={{ color: micFindings ? 'blue' : 'gray', cursor: 'pointer', fontSize: 30 }} />
                                                }

                                            </div>

                                            <div>
                                                <div style={{ marginTop: 10 }}  >
                                                    <p style={{ textAlign: 'left', color: '#5A8AFF' }} className="label">Medicine:</p>

                                                    <table id="medicinetable" style={{ width: '100%', paddingRight: 5, borderRadius: 5, marginTop: 20 }} >
                                                        <tr>
                                                            <th style={{ width: '20%', textAlign: 'center', paddingLeft: 5 }}>Medicine Name</th>
                                                            <th style={{ textAlign: 'center', width: '22%' }}>Dose</th>
                                                            <th style={{ textAlign: 'center', width: '20%' }}>When / How</th>
                                                            <th style={{ textAlign: 'center', width: '20%' }}>Frequency</th>
                                                            <th style={{ textAlign: 'center', width: '22%' }}>Duration</th>

                                                        </tr>
                                                    </table>
                                                </div>
                                                <div className="page-content">
                                                    <div className="container">
                                                        <div >
                                                            <table className="table table-striped" >
                                                                <thead>
                                                                </thead>
                                                                <tbody >

                                                                    <TableRows
                                                                        rows={medicineTableRows}
                                                                        tableRowRemove={tableRowRemove}
                                                                        onValUpdate={onValUpdate}
                                                                    />

                                                                </tbody>
                                                                <tfoot>
                                                                    <tr>
                                                                        <td colspan="5"></td>
                                                                        <td style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }} >
                                                                            <button style={{ fontSize: 20, marginTop: 5 }} className="btn btn-auto btn-xs btn-success" onClick={addRowTable}>
                                                                                +</button>
                                                                        </td>
                                                                    </tr>
                                                                </tfoot>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <textarea style={{ height: 100, fontSize: 14, fontWeight: '500', color: 'black', marginTop: 10 }} className="input-bordered input-textarea"
                                            value={medName} name="medName" onChange={(e) => medAdviceHandler(e)}
                                        ></textarea> */}
                                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 20, flexDirection: 'row' }}>
                                                    <input style={{ height: 50, width: '95%', fontSize: 14, fontWeight: '500', color: 'black' }} className="input-bordered input-textarea" placeholder="Search Medicine "
                                                        value={medicalSearch} name="testMed" onChange={(e) => setMedicineSearchHandler(e.target.value)}
                                                    />
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        startListeningMedicine()
                                                    }}
                                                    style={{ display: 'flex', justifyContent: 'flex-end', marginTop: -40 }}
                                                >
                                                    {
                                                        <Mic style={{ color: micMedicine ? 'blue' : 'gray', cursor: 'pointer', fontSize: 30 }} />

                                                    }
                                                </div>
                                            </div>
                                            <div style={{
                                                display: 'flex', flexDirection: 'column', alignItems: 'flex-start',
                                                margin: '4px',
                                                marginTop: '20px',
                                                overflowY: 'scroll',
                                                overflowX: 'hidden',
                                                maxHeight: '120px',
                                                boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
                                            }}>
                                                {
                                                    suggestionList.map((val, key) => {
                                                        return (
                                                            <div
                                                                onClick={() => {
                                                                    addRowTableWithData(val.name, val.dose, val.duration)
                                                                    // medicalCount.current = medicalCount.current + 1
                                                                    // setMedicalSearch('')
                                                                    // + '\t\t' + val.dose + '\t\t' + val.duration + '\n' + val.composition + '\n'

                                                                }}
                                                                key={key}
                                                                style={{
                                                                    width: '99%', margin: '4px',
                                                                    padding: '4px', borderRadius: '4px', textAlign: 'start', cursor: 'pointer'
                                                                }}>
                                                                <p>{val.name}</p>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>


                                            <div style={{ marginTop: 10 }} className="row">
                                                <div className="col-md-6">
                                                    <p style={{ textAlign: 'left', color: '#5A8AFF' }} className="label"> Diagnostic/Lab Investigation: </p>

                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', marginTop: 15 }}>


                                                        <textarea style={{ height: 120, fontSize: 14, fontWeight: '500', color: 'black' }} className="input-bordered input-textarea"
                                                            value={testMed}
                                                            name="testMed" onChange={(e) => testMedHandler(e)}
                                                        />
                                                    </div>

                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 20 }}>

                                                        <input style={{ height: 50, fontSize: 14, fontWeight: '500', color: 'black' }} className="input-bordered input-textarea" placeholder="Search Diagnostic "
                                                            value={investigationSearch} name="testMed" onChange={(e) => setInvestigationHandler(e.target.value)}
                                                        />
                                                        <div onClick={() => {
                                                            startListeningLab()
                                                        }}
                                                        >
                                                            <Mic style={{ color: micLab ? 'blue' : 'gray', cursor: 'pointer', marginLeft: 5, fontSize: 30 }} />
                                                        </div>
                                                    </div>

                                                    <div style={{
                                                        display: 'flex', flexDirection: 'column', alignItems: 'flex-start',
                                                        alignItems: 'flex-start',
                                                        margin: '4px',
                                                        marginTop: '20px',
                                                        overflowY: 'scroll',
                                                        overflowX: 'hidden',
                                                        maxHeight: '120px',
                                                        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
                                                    }}>
                                                        {
                                                            investigationList.map((val, key) => {
                                                                return (
                                                                    <div
                                                                        onClick={() => {
                                                                            investigationCount.current = investigationCount.current + 1
                                                                            const old = testMed
                                                                            setInvestigationSearch('')
                                                                            setTestMed(old + investigationCount.current + ':) ' + val.name + '\n')
                                                                            setInvestigationList([])
                                                                        }}
                                                                        key={key}
                                                                        style={{
                                                                            width: '99%', margin: '4px',
                                                                            padding: '4px', borderRadius: '4px', textAlign: 'start', cursor: 'pointer'
                                                                        }}>
                                                                        <p>{val.name}</p>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <p style={{ textAlign: 'left', color: '#5A8AFF' }} className="label"> Clinic Procedure: </p>
                                                    </div>

                                                    <div style={{ marginTop: 15, }}>
                                                        <Select
                                                            options={procedureList}
                                                            onChange={handleChangeProcedureList}
                                                            isMulti={true}
                                                            value={selectedProcedure}
                                                        />

                                                    </div>



                                                </div>
                                            </div>

                                            <div style={{ marginTop: 10 }} className="row">
                                                <div className="col-md-6">
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>

                                                        <p style={{ textAlign: 'left', color: '#5A8AFF' }} className="label"> Advice: </p>
                                                        <p style={{ textAlign: 'left', color: 'green', marginLeft: 15 }} className="label"> Do's: </p>
                                                    </div>


                                                    <textarea style={{ height: 120, marginTop: 10, fontSize: 14, fontWeight: '500', color: 'black', borderColor: '#90EE90' }} className="input-bordered input-textarea"
                                                        value={adviceDoes} name="adviceDoes" onChange={(e) => adviceDoesHandler(e)}

                                                    />

                                                </div>
                                                <div className="col-md-6">
                                                    <div style={{ display: 'flex', flexDirection: 'row' }}>

                                                        <p style={{ textAlign: 'left', color: '#5A8AFF' }} className="label"> Advice: </p>
                                                        <p style={{ textAlign: 'left', color: 'red', marginLeft: 15 }} className="label">  Don'ts: </p>
                                                    </div>
                                                    <textarea style={{ height: 120, marginTop: 10, fontSize: 14, fontWeight: '500', color: 'black', borderColor: '#FE7275' }} className="input-bordered input-textarea"
                                                        value={adviceDonts} name="adviceDonts" onChange={(e) => adviceDontsHandler(e)}

                                                    />

                                                </div>
                                            </div>


                                            <div style={{ marginTop: 20 }} className="row">
                                                <div className="col-md-6">
                                                    <div className="select-wrapper">
                                                        <select className="input-bordered"
                                                            // name="docid"
                                                            // value={docid}
                                                            onChange={(event) => onAdviceDoesUpdate(event)}

                                                        >
                                                            <option value="">Select</option>
                                                            {adviceDoesList.map((list) => {
                                                                return (
                                                                    <>
                                                                        <option value={list.value} >{list.value}</option>

                                                                    </>
                                                                );
                                                            })}

                                                        </select>

                                                    </div>

                                                </div>
                                                <div className="col-md-6">

                                                    <div className="select-wrapper">
                                                        <select className="input-bordered"

                                                            // name="docid"
                                                            // value={docid}
                                                            onChange={(event) => onAdviceDontsUpdate(event)}
                                                        >
                                                            <option value="">Select</option>
                                                            {adviceDontsList.map((list) => {
                                                                return (
                                                                    <>
                                                                        <option value={list.value} >{list.value}</option>
                                                                    </>
                                                                );
                                                            })}

                                                        </select>

                                                    </div>
                                                </div>
                                            </div>

                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <p style={{ textAlign: 'left', marginTop: 18, color: '#5A8AFF' }} className="label">  Next Visit: </p>

                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <div style={{ width: '39%' }}>

                                                    <div className="select-wrapper" style={{ marginTop: 10 }}>
                                                        <select className="input-bordered"
                                                            name="nextVisit"
                                                            value={nextVisit}
                                                            onChange={(event) => nextVisitHandler(event)}
                                                        >
                                                            <option value="">Select</option>
                                                            {nextVisitDayList.map((list) => {
                                                                return (
                                                                    <>
                                                                        <option value={list.value} >{list.value}</option>

                                                                    </>
                                                                );
                                                            })}

                                                        </select>

                                                    </div>
                                                </div>

                                                <div style={{ width: '60%' }}>
                                                    <p style={{ textAlign: 'left', marginTop: 15, fontSize: 16, marginLeft: 20 }} className="value">{showDate} </p>
                                                </div>



                                                <div >

                                                    <div className="flex-div">
                                                        {cancelling ? <p style={{ fontSize: 13, fontWeight: 'bold', color: 'red', marginTop: 5 }}>Cancelling...</p> :

                                                            <button onClick={() => setCancelConsultationModalOpen(true)} type="submit" className="btn btn-auto   btn-danger" style={{ marginLeft: 10 }}  >Cancel</button>

                                                        }



                                                        <div >
                                                            <Dialog
                                                                open={cancelConsultationModalOpen}
                                                                onClose={handleCloseCancelConsultation}
                                                                aria-labelledby="alert-dialog-title"
                                                                aria-describedby="alert-dialog-description"
                                                            >
                                                                <DialogTitle id="alert-dialog-title">
                                                                    {"Are you sure you want to cancel consultation?"}
                                                                </DialogTitle>
                                                                <DialogContent>
                                                                    <DialogContentText id="alert-dialog-description">

                                                                    </DialogContentText>
                                                                </DialogContent>
                                                                <DialogActions>


                                                                    {
                                                                        isButtonHide == false ?

                                                                            <Button onClick={handleCloseCancelConsultation}>No</Button>


                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        isButtonHide == false ?
                                                                            <form
                                                                                method="post"
                                                                                onSubmit={(event) => cancelConsultation(event)}
                                                                            >
                                                                                <Button type="submit" autoFocus>
                                                                                    Yes
                                                                                </Button>
                                                                            </form>
                                                                            :
                                                                            null

                                                                    }



                                                                </DialogActions>
                                                            </Dialog>

                                                        </div>

                                                        <div  >
                                                            <Dialog
                                                                open={forwardModalOpen}
                                                                onClose={handleCloseForward}
                                                                aria-labelledby="alert-dialog-title"
                                                                aria-describedby="alert-dialog-description"
                                                            >
                                                                <DialogTitle style={{ color: 'black' }} id="alert-dialog-title">
                                                                    {/* {"Are you sure you want to cancel consultation?"} */}
                                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>

                                                                        Forward patients to further procedure

                                                                        <Button onClick={handleCloseForward} style={{ marginLeft: 30 }} >Close</Button>

                                                                    </div>


                                                                </DialogTitle>
                                                                <DialogContent style={{ height: 250 }}
                                                                >
                                                                    <DialogContentText id="alert-dialog-description">

                                                                    </DialogContentText>
                                                                    <div>
                                                                        <p className="label">Select service type:</p>

                                                                    </div>



                                                                    <div style={{ width: '100%', marginTop: 7 }} className="select-wrapper">
                                                                        <select className="input-bordered"
                                                                            style={{ backgroundColor: 'white', color: 'black', border: '1px solid #CED4DA' }}
                                                                            name="servicetype"
                                                                            value={servicetype}
                                                                            onChange={(event) => serviceTypeUpdateHandler(event)}
                                                                        >
                                                                            <option value="" >Select</option>

                                                                            {serviceList.map((list) => {
                                                                                return (
                                                                                    <>
                                                                                        <option value={list.code} >{list.name}</option>
                                                                                    </>
                                                                                );
                                                                            })}

                                                                        </select>

                                                                    </div>

                                                                    {isChooseServiceType ?
                                                                        <>
                                                                            <div style={{ marginTop: 10 }}>
                                                                                <p className="label">Select service:</p>

                                                                            </div>

                                                                            {isShowServiceDropDown ?
                                                                                <>


                                                                                    <div style={{ width: '100%', marginTop: 7 }} className="select-wrapper">
                                                                                        <select className="input-bordered"
                                                                                            style={{ backgroundColor: 'white', color: 'black', border: '1px solid #CED4DA' }}
                                                                                            name="consultService"
                                                                                            value={consultService}
                                                                                            onChange={(event) => serviceUpdateHandler(event)}
                                                                                        >
                                                                                            <option value="" >Select</option>

                                                                                            {ConsultationServiceList.map((list) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <option value={list.id} >{list.fee_name}</option>
                                                                                                    </>
                                                                                                );
                                                                                            })}

                                                                                        </select>

                                                                                    </div>

                                                                                </>
                                                                                :
                                                                                <p style={{ textAlign: 'left', fontSize: 16, fontWeight: 'bold', color: 'red' }}>No services found for this service type.</p>
                                                                            }



                                                                            <form
                                                                                method="post"
                                                                                onSubmit={(event) => addServiceHandler(event)}
                                                                            >

                                                                                <button style={{ marginTop: 20, marginLeft: 'auto', marginRight: 'auto' }} type="submit" className="btn btn-auto   btn-success"   >Add Service</button>
                                                                            </form>


                                                                        </> : null
                                                                    }


                                                                </DialogContent>
                                                                <DialogActions>
                                                                </DialogActions>
                                                            </Dialog>

                                                        </div>

                                                        <button onClick={() => setConsultationModalOpen(true)} type="submit" className="btn btn-auto   btn-success" style={{ marginLeft: 15 }}  >Complete </button>
                                                        <button onClick={() => setForwardModalOpen(true)} type="submit" className="btn btn-auto btn-warning" style={{ marginLeft: 15 }}  >Forward </button>

                                                    </div>

                                                </div>

                                            </div>

                                            {/* <div style={{ marginTop: 20, textAlign: 'left' }}>
                                                <button onClick={switchPrescriptionHandler} type="submit" className="btn btn-auto  btn-warning" >Digital Consultation V2 </button>


                                            </div> */}
                                            <div className="col-md-4">

                                            </div>


                                            <div >
                                                {
                                                    audioFile ?
                                                        <Dialog
                                                            open={consultationModalOpen}
                                                            onClose={handleCloseConsultation}
                                                            aria-labelledby="alert-dialog-title"
                                                            aria-describedby="alert-dialog-description"
                                                        >
                                                            <DialogTitle id="alert-dialog-title">
                                                                {"Do you want to upload audio with complete consultation ?"}

                                                            </DialogTitle>
                                                            <DialogContent>
                                                                <DialogContentText id="alert-dialog-description">

                                                                </DialogContentText>
                                                            </DialogContent>
                                                            <DialogActions>

                                                                {isButtonHide == false ?
                                                                    <form
                                                                        method="post"
                                                                        onSubmit={(event) => completeWithAudioHandler(event)}
                                                                    >
                                                                        <Button type="submit" autoFocus>
                                                                            Yes
                                                                        </Button>
                                                                    </form>
                                                                    :
                                                                    null
                                                                }
                                                                {isButtonHide == false ?
                                                                    <form
                                                                        method="post"
                                                                        onSubmit={(event) => completeHandler(event)}
                                                                    >
                                                                        <Button type="submit" autoFocus>
                                                                            No
                                                                        </Button>
                                                                    </form>
                                                                    :
                                                                    null
                                                                }

                                                            </DialogActions>
                                                        </Dialog>
                                                        :
                                                        <Dialog
                                                            open={consultationModalOpen}
                                                            onClose={handleCloseConsultation}
                                                            aria-labelledby="alert-dialog-title"
                                                            aria-describedby="alert-dialog-description"
                                                        >
                                                            <DialogTitle id="alert-dialog-title">
                                                                {"Are you sure you want to complete consultation?"}
                                                            </DialogTitle>
                                                            <DialogContent>
                                                                <DialogContentText id="alert-dialog-description">

                                                                </DialogContentText>
                                                            </DialogContent>
                                                            <DialogActions>
                                                                {msg && <p style={{ fontSize: 18, fontWeight: 'bold', marginTop: 10, color: 'green', marginLeft: 'auto', marginRight: 'auto' }}>{msg}</p>}


                                                                {
                                                                    isButtonHide == false ?

                                                                        <Button onClick={handleCloseConsultation}>Cancel</Button>


                                                                        :
                                                                        null
                                                                }
                                                                {


                                                                    isButtonHide == false ?
                                                                        <form
                                                                            method="post"
                                                                            onSubmit={(event) => completeHandler(event)}
                                                                        >
                                                                            <Button type="submit" autoFocus>
                                                                                Complete
                                                                            </Button>
                                                                        </form>
                                                                        :
                                                                        null

                                                                }

                                                                {
                                                                    showPleaseWait ?
                                                                        <div  >
                                                                            <p style={{ fontSize: 14, fontWeight: 'bold', color: 'blue' }}>Please wait...</p>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }

                                                            </DialogActions>
                                                        </Dialog>

                                                }


                                            </div>
                                        </>
                                        :

                                        <>
                                            <div style={{ marginTop: 20 }} className="row">
                                                <div className="col-md-6">

                                                    <div className="token-sales card">
                                                        <div className="card-innr" style={{ marginLeft: -30 }}>


                                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                <p className="card-title"> Life Style</p>
                                                                {
                                                                    location.state.state.patients_id_fk != 0 ?
                                                                        <img style={{ height: 25, width: 25 }} src={edit} onClick={openLifeStyle} />
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                            {
                                                                isLifeStyle == true ?
                                                                    <>
                                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>

                                                                            <div style={{ width: '24%', textAlign: 'left' }}>
                                                                                <p style={{ color: '#5A8AFF' }} className="label">Smoke: </p>

                                                                            </div>
                                                                            <div style={{ width: '26%', textAlign: 'left' }}>
                                                                                <p className="value">{recoilLifeStyleInfoData.smoke}</p>

                                                                            </div>
                                                                            <div style={{ width: '24%', textAlign: 'left' }}>
                                                                                <p style={{ color: '#5A8AFF' }} className="label">Alcohol: </p>

                                                                            </div>
                                                                            <div style={{ width: '26%', textAlign: 'left' }}>
                                                                                <p className="value">&nbsp;{recoilLifeStyleInfoData.alcohol}</p>

                                                                            </div>
                                                                        </div>

                                                                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>

                                                                            <div style={{ width: '24%', textAlign: 'left' }}>
                                                                                <p style={{ color: '#5A8AFF' }} className="label">Exercise: </p>

                                                                            </div>
                                                                            <div style={{ width: '26%', textAlign: 'left' }}>
                                                                                <p className="value">{recoilLifeStyleInfoData.physical_activity}</p>

                                                                            </div>

                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <p style={{ fontSize: 15, fontWeight: 'bold', color: 'red', textAlign: 'left' }}>No data entered.</p>
                                                            }

                                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 20 }}>
                                                                <p className="card-title"> Health Details</p>
                                                            </div>
                                                            {
                                                                isHealthDetails == true ?
                                                                    <>

                                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                            <div style={{ width: '30%', textAlign: 'left' }}>
                                                                                <p style={{ color: '#5A8AFF' }} className="label">Allergy: </p>

                                                                            </div>
                                                                            <div style={{ width: '70%', textAlign: 'left' }}>
                                                                                {
                                                                                    healthDetails.allergies == "0" ?
                                                                                        <p className="value">No</p>
                                                                                        :
                                                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                                            <p className="value">Yes</p>
                                                                                            {healthDetails.allergies == "1" ?
                                                                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                                                    <p className="value">&nbsp;({healthDetails.allergies_info})</p>

                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                            }
                                                                                        </div>
                                                                                }
                                                                            </div>


                                                                        </div>
                                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                            <div style={{ width: '30%', textAlign: 'left' }}>
                                                                                <p style={{ color: '#5A8AFF' }} className="label">Diabetes: </p>

                                                                            </div>
                                                                            <div style={{ width: '70%', textAlign: 'left' }}>
                                                                                {
                                                                                    healthDetails.diabetes == "0" ?
                                                                                        <p className="value">No</p>
                                                                                        :
                                                                                        <div style={{ display: 'flex', flexDirection: 'row' }} >
                                                                                            <div style={{ display: 'flex', flexDirection: 'row' }}>

                                                                                                <p className="value">Yes</p>
                                                                                                {healthDetails.diabetes == "1" ?
                                                                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                                                        <p className="value">&nbsp;({healthDetails.diabetes_info})</p>

                                                                                                    </div>
                                                                                                    :
                                                                                                    null
                                                                                                }

                                                                                            </div>
                                                                                        </div>


                                                                                }
                                                                            </div>


                                                                        </div>



                                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                            <div style={{ width: '30%', textAlign: 'left' }}>
                                                                                <p style={{ color: '#5A8AFF' }} className="label">Hiv/Aids: </p>

                                                                            </div>
                                                                            <div style={{ width: '70%', textAlign: 'left' }}>
                                                                                {
                                                                                    healthDetails.hiv_aids == "0" ?
                                                                                        <p className="value">No</p>
                                                                                        :

                                                                                        <div style={{ display: 'flex', flexDirection: 'row' }} >
                                                                                            <div style={{ display: 'flex', flexDirection: 'row' }}>

                                                                                                <p className="value">Yes</p>
                                                                                                {healthDetails.hiv_aids == "1" ?
                                                                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                                                        <p className="value">&nbsp;({healthDetails.hiv_aids_info})</p>

                                                                                                    </div>
                                                                                                    :
                                                                                                    null
                                                                                                }

                                                                                            </div>
                                                                                        </div>
                                                                                }
                                                                            </div>



                                                                        </div>

                                                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                            <div style={{ width: '30%', textAlign: 'left' }}>
                                                                                <p className="label">Medication: </p>

                                                                            </div>
                                                                            <div style={{ width: '70%', textAlign: 'left' }}>
                                                                                <p className="value">{healthDetails.medication} </p>

                                                                            </div>


                                                                        </div>
                                                                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                                                                            <div style={{ width: '30%', textAlign: 'left' }}>
                                                                                <p className="label">Other Disease: </p>

                                                                            </div>
                                                                            <div style={{ width: '70%', textAlign: 'left' }}>
                                                                                <p className="value">{healthDetails.other_disease} </p>

                                                                            </div>


                                                                        </div>



                                                                    </>

                                                                    :
                                                                    <p style={{ fontSize: 15, fontWeight: 'bold', color: 'red', textAlign: 'left' }}>No data entered.</p>

                                                            }





                                                        </div>
                                                    </div>




                                                </div>


                                                <div className="col-md-6">

                                                    <div className="token-sales card">
                                                        <div className="card-innr" style={{ marginRight: -30 }}>

                                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                                <p className="card-title"> Family History</p>
                                                                {
                                                                    location.state.state.patients_id_fk != 0 ?
                                                                        <img style={{ height: 25, width: 25 }} src={edit} onClick={openFamilyHistory} />
                                                                        :
                                                                        null}
                                                            </div>

                                                            {isFamilyHistory1 == true || isFamilyHistory2 == true ?
                                                                <>
                                                                    <table id='prescriptiondata' style={{ width: '100%', marginTop: 15 }} >
                                                                        <thead>
                                                                            <tr>
                                                                                <th  >Disease</th>
                                                                                <th >Relation with Patient</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <td className="value" style={{ textAlign: 'left' }} >

                                                                                {familyHistoryDisease.map((val) => {
                                                                                    return (
                                                                                        <>
                                                                                            {
                                                                                                val.code == familyHistory1.family_history_1_disease ?
                                                                                                    <p  >{val.value}</p> : null
                                                                                            }
                                                                                        </>

                                                                                    )
                                                                                })}
                                                                            </td>
                                                                            <td className="value" style={{ textAlign: 'left' }}>
                                                                                {relation.map((val) => {
                                                                                    return (
                                                                                        <>
                                                                                            {
                                                                                                val.code == familyHistory1.family_history_1_relation ?
                                                                                                    <p >{val.value}</p> : null
                                                                                            }
                                                                                        </>

                                                                                    )
                                                                                })}
                                                                            </td>




                                                                        </tbody>
                                                                        <tbody>

                                                                            <td className="value" style={{ textAlign: 'left' }} >

                                                                                {familyHistoryDisease.map((val) => {
                                                                                    return (
                                                                                        <>
                                                                                            {
                                                                                                val.code == familyHistory2.family_history_2_disease ?
                                                                                                    <p  >{val.value}</p> : null
                                                                                            }
                                                                                        </>

                                                                                    )
                                                                                })}
                                                                            </td>
                                                                            <td className="value" style={{ textAlign: 'left' }}>

                                                                                {relation.map((val) => {
                                                                                    return (
                                                                                        <>
                                                                                            {
                                                                                                val.code == familyHistory2.family_history_2_relation ?
                                                                                                    <p >{val.value}</p> : null
                                                                                            }
                                                                                        </>

                                                                                    )
                                                                                })}
                                                                            </td>

                                                                        </tbody>

                                                                    </table>

                                                                </>
                                                                :
                                                                <p style={{ fontSize: 15, fontWeight: 'bold', color: 'red', textAlign: 'left' }}>No data entered.</p>
                                                            }


                                                            <p className="card-title" style={{ textAlign: 'left' }}>  Vaccination </p>
                                                            <div style={{ marginTop: 5, textAlign: 'left' }} >
                                                                {
                                                                    isVaccinationData == true ?
                                                                        <>
                                                                            <table id='prescriptiondata' style={{ width: '100%', paddingRight: 5, borderRadius: 5, }} >
                                                                                {

                                                                                    vaccinationInfo.map((value) => {
                                                                                        return (
                                                                                            <>
                                                                                                <td className="value">{value.name}</td>
                                                                                                <td className="value">{value.dose}</td>
                                                                                                <td className="value">{value.date}</td>


                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }


                                                                            </table>
                                                                        </>

                                                                        :
                                                                        <p style={{ marginTop: -15, fontSize: 15, fontWeight: 'bold', color: 'red', textAlign: 'left' }}>No data entered.</p>
                                                                }
                                                                {/* <table id='prescriptiondata' style={{ width: '100%', paddingRight: 5, borderRadius: 5, }} >
                                                                    <tr>
                                                                        <th  >Name</th>
                                                                        <th >Dose</th>
                                                                        <th >Date</th>
                                                                    </tr>
                                                                    <td className="value">Co-vaccine</td>
                                                                    <td className="value">1st </td>
                                                                    <td className="value">10-03-2023</td>
                                                                </table> */}
                                                            </div>


                                                        </div>
                                                    </div>

                                                </div>


                                            </div>



                                        </>


                                    }
                                </div>

                            </div>




                            <Dialog
                                open={modalOpen}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                    <div style={{ textAlign: 'right' }} >
                                        <Button style={{ color: 'red' }} onClick={handleClose}>CLOSE</Button>

                                    </div>
                                </DialogTitle>
                                <DialogContent style={{ width: 600 }}>
                                    <DialogContentText id="alert-dialog-description">
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                            <p className="card-title">Consultation History</p>
                                        </div>
                                        {isHistoryAvailable == true ?

                                            <div style={{ marginTop: 20, backgroundColor: 'white', width: 'auto', height: 'auto', overflowY: 'scroll', paddingLeft: 20, paddingRight: 20, paddingBottom: 20, borderRadius: 5, border: '1px solid rgb(203, 255, 217)', boxShadow: '2px 2px 2px 2px #dad9d9' }}>

                                                <p className="tap-on">Tap on card to see details below</p>
                                                {
                                                    historyData.map((value) => {
                                                        return (
                                                            <div key={value.id}

                                                            >
                                                                <div className="custom-card-consultation-history" onClick={() => { previousRecordHandler(value.id) }}>

                                                                    <div style={{ marginLeft: 5 }} className="row"
                                                                    >
                                                                        <div className="col-md-5">
                                                                            <p style={{ marginLeft: 5, fontWeight: 'bold' }} className="value"> {value.checkout_date_time}</p>

                                                                        </div>
                                                                        <div className="col-md-7">
                                                                            <div className="flex-div" style={{ textAlign: 'left' }}>
                                                                                <p style={{ marginLeft: 5, fontWeight: 'bold' }} className="value"> {value.visiting_purpose}</p>

                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )

                                                    })

                                                }

                                            </div>
                                            :
                                            <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>

                                                <p className="no-records">No history entered</p>
                                            </div>

                                        }

                                        {isopenDetails ?

                                            <div style={{ marginTop: 50 }} className="custom-card-consultation-history">
                                                {docPrescriptions.map((val) => {
                                                    return (
                                                        <div >
                                                            <div style={{ marginTop: 20 }} >
                                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>

                                                                    <p className="card-title">Consultation Details</p>
                                                                </div>


                                                                <div style={{ marginTop: 10 }} className="flex-div">
                                                                    <p className="label" >Date - Time:&nbsp;</p><p className="value">{val.checkout_date_time}</p>
                                                                    <p className="label" style={{ marginLeft: 20 }} >Consultation Id:&nbsp;</p><p className="value">{val.consultation_id}</p>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-2">
                                                                        <p style={{ textAlign: 'left' }} className="label">Complaints:</p>

                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <p style={{ textAlign: 'left', marginLeft: -10 }} className="value">&nbsp;{val.chief_complaints}</p>

                                                                    </div>

                                                                </div>
                                                                <div style={{ marginTop: 10 }} className="row">
                                                                    <div className="col-md-2">
                                                                        <p style={{ textAlign: 'left' }} className="label">Findings:</p>

                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <p style={{ textAlign: 'left', marginLeft: -10 }} className="value">&nbsp;{val.doctor_findings}</p>

                                                                    </div>

                                                                </div>

                                                                <p style={{ marginTop: 10 }} className="label" >Diagnostic/Lab: </p>
                                                                <p style={{ marginTop: -10 }} className="value" >{val.doctor_investigation}</p>
                                                                <p className="label" > Medicine: </p>
                                                                <p style={{ marginTop: -10 }} className="value" >{val.doctor_advice}</p>
                                                                <div style={{ marginTop: 10 }} className="row">
                                                                    <div className="col-md-2">
                                                                        <p style={{ textAlign: 'left' }} className="label">Recordings:</p>

                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <audio src={audioRecFile} style={{ border: 2 }} controls />

                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    );
                                                })}
                                                {ispreviuosRecord && patientListClick ?

                                                    <>
                                                        <p className="label" style={{ marginTop: 20 }}>Documents</p>
                                                        <div className="main-column-container">


                                                            {
                                                                previousDocuments.map((details) => {
                                                                    return (
                                                                        <div key={details.file} style={{ textAlign: 'left', paddingTop: 10 }} onClick={() => { openNewTabHandler(details.file) }}    >
                                                                            <div style={{ display: 'flex', flexDirection: 'row', width: '60%' }}>
                                                                                <div style={{ width: '15%' }}>
                                                                                    <img className="doc-image" src={documents} />

                                                                                </div>
                                                                                <div style={{ width: '85%' }}>
                                                                                    <p className="value">{details.document_name}</p>

                                                                                    <p className="value">{details.create_date_time}</p>
                                                                                </div>


                                                                            </div>

                                                                        </div>
                                                                    )
                                                                })
                                                            }


                                                        </div>

                                                    </> :
                                                    null
                                                }

                                            </div>
                                            :
                                            null
                                        }
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>

                                </DialogActions>
                            </Dialog>


                        </div>



                        <Dialog
                            open={modalOpenVitals}
                            onClose={handleModalCloseVitals}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"

                        >
                            <DialogTitle id="form-dialog-title" fullWidth={true}

                            >
                                <div style={{ textAlign: 'right' }}     >

                                    <Button style={{ color: 'red', marginTop: -10 }} onClick={handleModalCloseVitals}>CLOSE</Button>
                                </div>
                                {"Vitals"}
                            </DialogTitle>
                            <DialogContent
                            >
                                <DialogContentText id="alert-dialog-description">

                                    <VitalsInformation
                                        height={vitalsInfo.height}
                                        weight={vitalsInfo.weight}
                                        bpmm={vitalsInfo.blood_pressure_mm}
                                        bphg={vitalsInfo.blood_pressure_hg}
                                        bloodsugar={vitalsInfo.blood_sugar_random}
                                        pulserate={vitalsInfo.pulse_rate}
                                        temperature={vitalsInfo.temperature}
                                        id={consultationId}

                                    />

                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                            </DialogActions>
                        </Dialog>



                        <Dialog
                            open={modalOpenVitals}
                            onClose={handleModalCloseVitals}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"

                        >
                            <DialogTitle id="form-dialog-title" fullWidth={true}

                            >
                                <div style={{ textAlign: 'right' }}     >

                                    <Button style={{ color: 'red', marginTop: -10 }} onClick={handleModalCloseVitals}>CLOSE</Button>
                                </div>
                                {"Vitals"}
                            </DialogTitle>
                            <DialogContent
                            >
                                <DialogContentText id="alert-dialog-description">

                                    <VitalsInformation
                                        height={vitalsInfo.height}
                                        weight={vitalsInfo.weight}
                                        bpmm={vitalsInfo.blood_pressure_mm}
                                        bphg={vitalsInfo.blood_pressure_hg}
                                        bloodsugar={vitalsInfo.blood_sugar_random}
                                        pulserate={vitalsInfo.pulse_rate}
                                        temperature={vitalsInfo.temperature}
                                        id={consultationId}

                                    />

                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={modalOpenFamilyHistory}
                            onClose={handleModalCloseFamilyHistory}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"

                        >
                            <DialogTitle id="form-dialog-title" fullWidth={true}
                            >
                                <div style={{ textAlign: 'right' }}     >

                                    <Button style={{ color: 'red', marginTop: -10 }} onClick={handleModalCloseFamilyHistory}>CLOSE</Button>
                                </div>
                                {"Family History"}
                            </DialogTitle>
                            <DialogContent
                            >
                                <DialogContentText id="alert-dialog-description">

                                    <FamilyHistoryInformation
                                        history1={familyHistory1}
                                        history2={familyHistory2}
                                        id={consultationId}
                                        patientid={patientsId}

                                    />

                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={modalOpenLifeStyle}
                            onClose={handleModalCloseLifeStyle}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"

                        >
                            <DialogTitle id="form-dialog-title" fullWidth={true}

                            >
                                <div style={{ textAlign: 'right' }}     >

                                    <Button style={{ color: 'red', marginTop: -10 }} onClick={handleModalCloseLifeStyle}>CLOSE</Button>
                                </div>
                                {"Life Style"}
                            </DialogTitle>
                            <DialogContent
                            >
                                <DialogContentText id="alert-dialog-description">

                                    <LifeStyleInformation
                                        smoke={recoilLifeStyleInfoData.smoke}
                                        alcohol={recoilLifeStyleInfoData.alcohol}
                                        exercise={recoilLifeStyleInfoData.physical_activity}
                                        id={consultationId}
                                        patientid={patientsId}
                                    />
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                            </DialogActions>
                        </Dialog>

                        {/* <div className="aside sidebar-right col-lg-4">
                            <div className="token-sales card">
                                <div className="card-innr">


                                    <div style={{ display: 'flex', flexDirection: 'row' }}>

                                        <div style={{ width: '60%' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>


                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>






                            <div className="token-sales card">
                                <div className="card-innr">
                                    <div >
                                        <button onClick={consultationHistoryHandler} type="submit" className="btn btn-auto   btn-primary" >Consultation History</button>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    )
}