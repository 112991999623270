import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from '../components/Header';
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import BackToDashboard from '../components/BackToDashboard';
import InvoiceTopNavbar from '../components/InvoiceTopNavbar';
import { useRecoilState } from 'recoil';
import { feeMasterDocName } from '../Store/FeeMaster';

let array_data;
function TableRows({ rows, tableRowRemove, onValUpdate }) {
  const [feeMasterRow, setFeeMasterRow] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const [isShowDoc, setIsShowDoc] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const [procedureList, setProcedureList] = useState([]);

  useEffect(() => {
    axios.get(`${global.baseUrl}/api/clinicapi/Doctors/get_clinic_doctor_list?clinic_id=${localStorage.getItem("clinic_id")}`
    ).then(
      (response) => {
        if (response.data.status == '1') {
          console.log(response.data.response);
          setDoctorList(response.data.response);
        }

      }

    ).catch((error) => {
      console.log(error.data);
    });

    axios.get(`${global.baseUrl}/api/commonapi/PickList/get_services`
    ).then(
      (response) => {

        console.log("service list = ", response.data.response);
        setServiceList(response.data.response);

      }

    ).catch((error) => {
      console.log(error.data);
    });
    axios.get(`${global.baseUrl}/api/commonapi/PickList/get_procedure_list`
    ).then(
      (response) => {

        console.log("procedure_list = ", response.data.response);
        setProcedureList(response.data.response);

      }

    ).catch((error) => {
      console.log(error.data);
    });


  }, [])




  return rows.map((rowsData, index) => {
    const { fee_name, fee_type, amount, doctors_id_fk } = rowsData;

    return (

      <tr key={index}>
        <td>



          <div style={{ width: '100%' }} className="select-wrapper">
            <select className="input-bordered"
              style={{ backgroundColor: 'white', color: 'black', border: '1px solid #CED4DA' }}
              name="fee_type"
              value={fee_type}
              onChange={(event) => onValUpdate(index, event)}
            >
              <option value="" >Select</option>

              {serviceList.map((list) => {
                return (
                  <>
                    <option value={list.code} >{list.name}</option>
                  </>
                );
              })}

            </select>

          </div>

        </td>



        <td>
          {
            fee_type == "pro" ?


              <select className="input-bordered"
                style={{ backgroundColor: 'white', color: 'black', border: '1px solid #CED4DA' }}
                name="fee_name"
                value={fee_name}
                onChange={(event) => onValUpdate(index, event)}
              >
                <option value={""} >Select</option>

                {procedureList.map((list) => {
                  return (
                    <>
                      <option value={list.service_name} >{list.service_name}</option>
                    </>
                  );
                })}

              </select>
              :
              <input
                type="text"
                value={fee_name}
                onChange={(event) => onValUpdate(index, event)}
                name="fee_name"
                className="form-control"
                style={{ fontSize: 13, height: 42 }}
                maxLength={40}
              />
          }



        </td>


        <td>

          {
            fee_type == "con" ?


              <div style={{ width: '100%' }} className="select-wrapper">
                <select className="input-bordered"
                  style={{ backgroundColor: 'white', color: 'black', border: '1px solid #CED4DA' }}
                  name="doctors_id_fk"
                  value={doctors_id_fk}
                  onChange={(event) => onValUpdate(index, event)}
                >
                  <option value={""} >Select</option>

                  {doctorList.map((list) => {
                    return (
                      <>
                        <option value={list.id} >{list.name}</option>
                      </>
                    );
                  })}

                </select>

              </div>
              : null
          }

        </td>
        <td>
          <input
            type="number"
            value={amount}
            onChange={(event) => onValUpdate(index, event)}
            name="amount"
            className="form-control"
            style={{ fontSize: 13, height: 42 }}

          />
        </td>
        <td style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} >
          <button
            style={{ fontSize: 30, marginLeft: 10, marginTop: 5, marginRight: 5 }} className="btn btn-auto btn-xs btn-danger" onClick={() => tableRowRemove(index)}

          >-
          </button>
        </td>
      </tr>
    );

  });
}
function FeeMasterAdd({ route, navigation }) {
  const navigate = useNavigate();


  // const location = useLocation();
  // console.log(location.state);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [docName, setDocName] = useState("");
  const [rows, initRow] = useState([]);
  const [isButtonHide, setIsButtonHide] = useState(false);

  let fee_master_list = {
    display: "none",
  };


  const [feeMasterRow, setFeeMasterRow] = useState([]);

  const [docId, setDocId] = useState('');
  const [clinicId, setClinicId] = useState('');
  const [userrole, setUserrole] = useState("");

  useEffect(() => {

    setUserrole(localStorage.getItem("user_role"));
    // setDocId(localStorage.getItem("doctorId"))
    setClinicId(localStorage.getItem("clinic_id"))
    console.log("cid", localStorage.getItem("clinic_id"))



    // setDocName(localStorage.getItem("docname"))








  }, [])


  let submit_button = {
    margin: "2vh",
  };
  const addRowTable = () => {


    const data = {
      fee_name: "",
      fee_type: "",
      amount: "",
      doctors_id_fk: docId,
      clinic_id_fk: clinicId
    };
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);
  };
  const onValUpdate = (i, event) => {

    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    console.log("data array, ", data);
    console.log("index, ", data[i]);
    console.log("doc id, ", data[i].doctors_id_fk);
    console.log("fee_name, ", data[i].fee_name);
    console.log("fee_name, ", data[i].fee_type);

    initRow(data);
    if (data[i].fee_type != "") {

      array_data = [JSON.stringify(data)];

    }


    axios.get(`${global.baseUrl}/api/clinicapi/Doctors/get_doctor_name?docid=${data[i].doctors_id_fk}`
    ).then(
      (response) => {
        console.log("doctor Name= ", response.data.response);
        setDocName(response.data.response.name)
      }

    ).catch((error) => {
      console.log(error.data);
    });

  };

  const onclicklist = (e) => {
    document.getElementById("list").style.display = "block";
  }


  const onsubmitform = (e) => {
    if (array_data != null) {

      e.preventDefault();
      setErrorMessage("");
      //  alert(array_data);
      // // alert("submit");
      setIsButtonHide(true);
      let formdata = $(e.target);
      formdata = new FormData();
      formdata.append('docName', docName);
      formdata.append('array_data', array_data);
      $.ajax({
        type: "POST",
        url: `${global.baseUrl}/api/clinicapi/FeeMaster/add_fee`,
        data: formdata,
        processData: false,
        contentType: false,
        success(data) {
          console.log(data);
          if (data === 'success') {
            setIsButtonHide(true);
            setSuccessMessage("Inserted Successfully");
            setTimeout(() => {
              navigate("/FeeMasterList");
            }, 2000)

          } else {
            setIsButtonHide(false);
            setErrorMessage("Save Failed");
          }

        },
        error: function (err) {
          console.log(err);

        }
      });
    }
    else {
      e.preventDefault();
      setErrorMessage("You can't save empty data ")
    }

  };

  return (
    <>
      <div className="page-user">
        <div className="topbar-wrap">
          <Header />


        </div>
        <div style={{ marginTop: -35 }}>

          <InvoiceTopNavbar />
        </div>


        <div className="page-content">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4">



              </div>
              <div className="col-xl-4 col-lg-4">

                <h6 className="card-title"> Service Fees</h6>

              </div>
              <div className="col-xl-4 col-lg-4">


              </div>
            </div>
          </div>
        </div>
        <div className="page-content">
          <div className="container">
            <div style={{ marginTop: 20 }}>
              <table id="customers">
                <thead>
                  <tr>
                    <th style={{ width: "400px", textAlign: 'center', fontWeight: 'normal', fontSize: 13 }}>Service Type</th>
                    <th style={{ width: "400px", textAlign: 'center', fontWeight: 'normal', fontSize: 13 }}>Service Name</th>
                    <th style={{ width: "400px", textAlign: 'center', fontWeight: 'normal', fontSize: 13 }}>Choose Doctor</th>
                    <th style={{ width: "300px", textAlign: 'center', fontWeight: 'normal', fontSize: 13 }}>Amount</th>
                    <th style={{ width: "300px", textAlign: 'center', fontWeight: 'normal', fontSize: 13 }}>Action</th>

                  </tr>
                </thead>
                <tbody>

                  <TableRows
                    rows={rows}
                    tableRowRemove={tableRowRemove}
                    onValUpdate={onValUpdate}
                  />

                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="4"></td>
                    <td style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }} >
                      <button style={{ fontSize: 20, marginLeft: 10, marginTop: 5, marginRight: 5 }} className="btn btn-auto btn-xs btn-success" onClick={addRowTable}>
                        +</button>

                    </td>
                  </tr>

                </tfoot>


              </table>
              {
                isButtonHide == false ?
                  <button type="submit" onClick={onsubmitform} className="btn btn-info" style={submit_button}>Save</button>
                  : null
              }

              {errorMessage && <div style={{ marginTop: 10, fontSize: 14, fontWeight: 'bold', color: 'red' }} className="error"> {errorMessage} </div>}
              {successMessage && <div style={{ marginTop: 10, fontSize: 14, fontWeight: 'bold', color: 'green' }} className="error"> {successMessage} </div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default FeeMasterAdd;