import { atom } from 'recoil';

export const checked_in_patient_list = atom({
    key: 'checked_in_patient_list',
    default: [],
});
export const checked_in_patient_onboard_list = atom({
    key: 'checked_in_patient_onboard_list',
    default: false,
});
export const addNewPatientHandeler = atom({
    key: 'addNewPatientHandeler',
    default: false,
});
