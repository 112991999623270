import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl, networkCall } from "./Network";
export const fetchDoctorBookings = createAsyncThunk(
  "bookings/fetch",
  async (payload) => {
    const response = await networkCall(
      "GET",
      `get_doctors_booked_slots_by_date?clinicId=${localStorage.getItem("clinic_id")}
      )}&doctorId=${payload?.doctorId}&date=${payload?.date}`
    );

    return response.response;
  }
);
export const fetchDoctorBookingsSlots = createAsyncThunk(
  "bookings/fetch",
  async (payload) => {
    const response = await networkCall(
      "GET",
      ` view_appointments_by_date?clinicId=${localStorage.getItem("clinic_id")}
      )}&doctorId=${payload?.doctorId}&date=${payload?.date}`
    );
    return response.response;
  }
);

export const fetchSchedule = createAsyncThunk(
  "bookings/fetchSchedue",
  async (payload) => {
    const response = await networkCall(
      "GET",
      `get_all_doctor_slots?doctorsId=${payload?.doctorId}&clinicId=${payload?.clinicId}`
    );
    if (response.status === 1) {
      return response.response;
    }
    throw new Error("Something Went Wrong");
  }
);

export const addSchedule = createAsyncThunk(
  "bookings/schedule",
  async (payload) => {
    try {
      let formData = new FormData();
      formData.append("array_data", JSON.stringify(payload));
      const response = await fetch(baseUrl + "add_schedule", {
        method: "POST",
        body: formData,
      });
      const data = await response.text();
      return data;
    } catch (error) {
      throw error;
    }
  }
);
const BookingSlice = createSlice({
  name: "bookings",
  initialState: {
    error: null,
    loading: false,
    bookingList: [],
    slotList: [],
  },
  reducers: {
    mutateSlotList(state, action) {
      console.log(action.payload);
      state.slotList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDoctorBookings.pending, (state) => {
        state.loading = true;
        state.bookingList = [];
        state.error = null;
      })
      .addCase(fetchDoctorBookings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        alert(action?.error);
      })
      .addCase(fetchDoctorBookings.fulfilled, (state, action) => {
        state.loading = false;
        state.bookingList = action.payload;
      });

    builder
      .addCase(addSchedule.pending, (state) => {
        state.loading = true;
      })
      .addCase(addSchedule.rejected, (state, action) => {
        alert(action.error.message);
        state.loading = false;
      })
      .addCase(addSchedule.fulfilled, (state) => {
        state.loading = false;
        alert("Slots Added Successfully");
      });

    builder
      .addCase(fetchSchedule.pending, (state) => {
        state.loading = true;
        state.slotList = [];
      })
      .addCase(fetchSchedule.rejected, (state, action) => {
        state.loading = false;
        alert(action.error?.message || "");
      })
      .addCase(fetchSchedule.fulfilled, (state, action) => {
        state.loading = false;
        state.slotList = action?.payload || [];
      });
  },
});

export const { mutateSlotList } = BookingSlice.actions;
export default BookingSlice.reducer;
